.wrapper > * {
  padding: var(--unit_0) var(--unit_2) 3px var(--unit_2);
  display: inline-flex;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  text-decoration: none;
  text-transform: lowercase;
  color: var(--black_100);
  background: var(--black_6);
  border-radius: var(--unit_1);
}
