.content {
  position: relative;
  padding: 21px 0 0;
  min-height: 100%;
  background-color: white;
}

.article-container {
  margin-top: 0;
}

.content__container {
  padding-right: 20px;
}

.content__read-topic {
  margin-top: 60px;
  padding-left: 7px;
  background: #f4f4f4;
}

.content-bottom-block {
  padding-left: 13px;
}

.search-visible {
  filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.95);
}

.content-search-visible {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: calc(100% - 110px);
  min-height: auto;
}

.adfox-banner-footer {
  &:empty {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .content {
    padding: 13px 0 0;
  }

  .content._with-new-menu {
    padding-top: 0;
  }

  .content__container {
    padding: 0;
  }

  .content__container_info {
    padding: 0;
  }

  .content__read-topic {
    padding-left: 0;
  }

  /* чекнуть */
  .content-bottom-block {
    margin-top: 0;
    padding: 0 calc(20px - 0.5rem) 0 20px;
  }

  .sidebar {
    width: 100%;
  }

  .content-search-visible {
    height: calc(100% - 50px);
  }
}

@media screen and (max-width: 768px) {
  .content__container {
    min-width: 300px;
  }

  .content__read-topic {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .content {
    padding: 13px 0 0;
    overflow: hidden;
  }

  .content__container_info {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .content__container._with-new-menu {
    display: flex;
  }

  .content__container._with-new-menu > div:nth-child(2) {
    z-index: 0;
    width: 100%;
  }

  .sidebar-new {
    z-index: 1;
    margin-top: calc(-1 * var(--unit_5));
    width: 240px;
    min-width: 240px;
  }

  .content__container_info._with-new-menu {
    width: calc(100% - 0.5rem);
  }

  .adfox-banner-footer {
    padding-top: var(--unit_10, 40px);
    padding-bottom: var(--unit_8, 32px);
  }
}
