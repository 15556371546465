.wrapper {
  margin: 0 var(--unit_6) var(--unit_2) var(--unit_6);
  padding: 0;
  display: block;
  max-width: 174px;
  list-style: none;
}

.wrapper + .wrapper {
  padding-top: var(--unit_2);
  border-top: 1px solid var(--gray10_100);
}
