.tags {
  margin-top: 27px;
  padding-bottom: 25px;
  font-size: 12px;
  line-height: 1.67;
  border-bottom: 2px solid #121212;
}

.tags_schema_org {
  display: none;
}

.tags__link {
  margin-right: 12px;
  display: inline-block;
  font-size: 10px;
  font-weight: 900;
  line-height: 2;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7a7a7a;
}

.tags__link:hover {
  cursor: pointer;
  color: #121212;
}

@media screen and (max-width: 540px) {
  .tags {
    margin-top: 13px;
    padding-bottom: 24px;
  }
}
