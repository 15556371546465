.wrapper {
  display: flex;
  align-items: center;
  gap: var(--unit_2);
}

.wrapper > svg {
  margin: var(--unit_1);
  color: var(--icon-primary-default);
}
