.wrapper {
  display: flex;
  align-items: center;
  gap: var(--unit_3);
}

.wrapper > *:first-child {
  flex: 0 0 auto;
}

.wrapper img {
  min-width: 33px;
  min-height: 33px;
}
