.wrapper {
  padding: var(--unit_1);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.wrapper > svg {
  color: var(--black_100);
}

.text {
  padding-left: var(--unit_2);
  font-size: var(--fontSize_14px);
  font-weight: bold;
  line-height: var(--lineHeight_20px);
  letter-spacing: -0.2px;
  color: var(--black_100);
}

.modal-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
