.wrapper {
  padding: var(--unit_3) 0;
  border-top: 1px solid var(--gray10_100);
}

.button {
  cursor: pointer;
}

.button:hover {
  color: var(--primary_100);
  transition: color 200ms ease-in-out;
}
