.wrapper {
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
  gap: var(--unit_4);
  background: var(--gray6_100);
  border-radius: 8px;
}

.wrapper._gray {
  background: var(--gray6_100);
}

.wrapper._blue {
  background: var(--blue-gradient);
}

.wrapper._pink {
  background: var(--pink-gradient);
}

.wrapper._green {
  background: var(--green-gradient);
}

.wrapper._lightblue {
  background: var(--lightblue-gradient);
}

.image-wrapper {
  display: flex;
  flex: 0 0 auto;
}

.image-wrapper img {
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  height: 100% !important;
  object-fit: contain;
  pointer-events: none;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--unit_4);
}

.text {
  font-size: var(--fontSize_16px);
  font-weight: 500;
  font-style: italic;
  line-height: var(--lineHeight_24px);
  letter-spacing: -0.5px;
  color: var(--black_100);
}

.text::before {
  content: '«';
}

.text::after {
  content: '».';
}

.wrapper._blue .text {
  color: var(--white_100);
}

@media screen and (min-width: 641px) {
  .wrapper {
    padding: var(--unit_6);
    flex-direction: row;
    align-items: center;
    gap: var(--unit_6);
  }

  .image-wrapper {
    width: 100%;
    align-items: center;
    align-self: flex-start;
    max-width: 207px;
  }

  .image-wrapper > * {
    height: 228px;
  }

  .image-wrapper img {
    object-fit: cover;
  }

  .content {
    gap: var(--unit_6);
  }

  .text {
    font-size: var(--fontSize_22px);
    line-height: var(--lineHeight_28px);
  }
}
