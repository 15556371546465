.wrapper {
  background: var(--gray6_100);
}

.wave-animation {
  animation: 1.5s ease-in-out 0.5s infinite normal none running wave-animation;
}

.text-22 {
  border-radius: 4px;
}

.text-22 {
  height: var(--lineHeight_28px);
}

.text-22:not(.has-height) {
  transform: scale(1, 0.6);
}

@keyframes wave-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
