/* Blog add note */
.blog-add {
  width: 100%;
  color: #121212;
  background-color: white;
}

.hidden-block {
  display: none;
}

.custom-row {
  margin-top: 0;
  margin-bottom: 10px;
  width: 100%;
}

.blog-add-content_main-heading {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blog-add-content {
  padding: 2px 30px 40px;
  border: 1px solid #e4e4e4;
  border-top: 2px solid #182930;
  border-radius: 0.5px;
}

.blog-add-content_heading {
  margin: 20px 0 10px;
  font-size: 14px;
  line-height: 1.43;
  color: #121212;
}

.blog-add-content_name-wrap,
.blog-add-content_email-wrap {
  width: calc(50% - 10px);
}

.blog-add-content_email-wrap {
  margin-right: 20px;
}

.blog-add-content_name-wrap {
  margin-right: 0;
}

.blog-add-content_email,
.blog-add-content_name {
  margin-top: 10px;
}

.user_credentials_heading {
  margin: 20px 0 10px;
  font-size: 14px;
  color: #121212;
}

.blog-add-content-category {
  margin-right: 12px;
  display: inline-block;
}

.blog-add-content_heading-tag-region {
  margin-bottom: 7px;
}

.blog-add-content-region {
  display: inline-block;
  width: 100%;
}

.relative {
  position: relative;
}

.arrow {
  position: absolute;
  top: 8px;
  right: 12px;
  width: 10px;
  height: 6px;
  background: url('img/arrow.svg');
}

.blog-add-content-tags_wrap {
  position: relative;
}

.blog-add-content_dropdown {
  position: absolute;
  z-index: 2;
  top: 32px;
  left: 0;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  width: 100%;
  max-height: 260px;
  list-style: none;
  background: white;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.27);
}

.blog-add-content_dropdown-item {
  padding: 5px 15px 7px;
}
.blog-add-content_dropdown-item:hover {
  cursor: pointer;
  background: rgba(43, 135, 219, 0.1);
}

.blog-add-content_select {
  padding: 3px 16px 5px;
  width: 132px;
  height: 28px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: #121212;
  appearance: none;
  background-color: transparent;
  border: solid 1px #c9c9c9;
  border-radius: 42px;
  transition: width 300ms;
}
.blog-add-content_select:focus {
  outline: none;
  box-shadow: 0 0 2pt 1pt #ff9d00;
}
.blog-add-content_chip {
  margin: 8px 0;
  padding-right: 12px;
  padding-left: 12px;
  display: inline-block;
  height: 24px;
  font-size: 13px;
  color: #7a7a7a;
  background-color: #f4f4f4;
  border-radius: 12px;
}
.blog-add-content_chip:not(:last-child) {
  margin-right: 8px;
}
.blog-add-content_chip-close {
  cursor: pointer;
}

.blog-credentials_input-tag {
  padding: 2px 7px 4px;
  width: 100%;
  height: 28px;
  border: solid 1px #c9c9c9;
  border-radius: 4px;
  outline: none;
  appearance: none;
}
.blog-credentials_input-tag:focus {
  border: solid 1px #121212;
}

.blog-add-content_input-tag,
.blog-add-content_input-tag-mobile {
  padding: 2px 7px 4px;
  width: 100%;
  height: 28px;
  border: solid 1px #c9c9c9;
  border-radius: 4px;
  outline: none;
  appearance: none;
}
.blog-add-content_input-tag:focus,
.blog-add-content_input-tag-mobile:focus {
  border: solid 1px #121212;
}
.blog-add-content_input-tag-mobile {
  display: none;
}
.blog-add-content-footer {
  padding-top: 20px;
  width: 100%;
}
.blog-add-content-footer button:first-child {
  margin-right: 12px;
  margin-bottom: 12px;
}

/* Sidebar */
.blog-add-bside {
}

.blog-add-bside section:nth-child(2) {
  margin-top: 20px;
}

/* about-tip-block */
.blog-add-bside-tip {
  margin-right: 13px;
  margin-left: auto;
  max-width: 300px;
  border: solid 1px #e4e4e4;
  border-radius: 1px;
  transition: all 300ms;
}
.blog-add-bside-tip h3 {
  margin: 0;
  padding: 21px 13px 19px 0;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: solid 1px #e4e4e4;
}
.blog-add-bside-tip-list {
  margin: 0;
  padding: 18px 20px 19px 36px;
}
.blog-add-bside-tip-list li {
  margin: 15px 0;
  color: #7a7a7a;
}
.blog-add-bside-tip-list li:first-child {
  margin-top: 0;
}
.blog-add-bside-tip-list li:last-child {
  margin-bottom: 0;
}
.blog-add-bside-tip-list li > span {
  color: #121212;
}
/* source-tip-block */
.blog-add-bside-tip-source {
  border-radius: 1px;
  border: solid 1px #e4e4e4;
  max-width: 300px;
  margin: 20px 13px 0 auto;
  background-color: #fdecef;
  transition: all 300ms;
}
.blog-add-bside-tip-source h3 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 21px 0 19px;
  border-bottom: solid 1px #e4e4e4;
  margin: 0;
  padding-right: 13px;
}
.blog-add-bside-tip-source p {
  padding-top: 16px;
  padding-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
}
.blog-add-bside-tip_title-mobile,
.blog-add-bside-tip-source_title-mobile {
  display: none;
}
.blog-add-bside-tip_title-dekstop,
.blog-add-bside-tip-source_title-dekstop {
  display: block;
}

.blog-add-bside-realtor-demand {
  margin: 16px 13px 0 auto;
  max-width: 300px;
}
/* UPLOAD SECTION */

.blog-add-content-upload {
}
.blog-add-content-upload_input-wrap {
  position: relative;
  height: 25px;
}
.blog-add-content-upload_input-wrap label {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.blog-add-content-upload_input-wrap span {
  padding: 3px 17px;
  font-weight: bold;
  cursor: pointer;
  color: #2b87db;
  border: 1px solid rgba(43, 135, 219, 0.5);
  border-radius: 42px;
}
.blog-add-content-upload_input-wrap span:focus {
  outline: none;
  box-shadow: 0 0 2pt 1pt #ff9d00;
}
.blog-add-content-upload_input-wrap span:hover,
.blog-add-content-upload_input-wrap span:active {
  color: white;
  background-color: #2b87db;
}
.blog-add-content-upload_input-wrap span::after {
  position: absolute;
  left: 200px;
  bottom: 0;
  padding: 5px 12px 7px 9px;
  display: none;
  white-space: nowrap;
  content: 'Недопустимый формат файла';
  color: white;
  background-color: #ff5050;
  border-radius: 4px;
}
.blog-add-content-upload_input-wrap span::before {
  position: absolute;
  left: 195px;
  bottom: 4px;
  display: none;
  width: 0;
  height: 0;
  content: '';
  border-width: 11px 6px 11px 0;
  border-style: solid;
  border-color: transparent #ff5050 transparent transparent;
}
.loader {
  position: absolute;
  top: -10px;
  left: 80px;
  margin: 10px 0;
  display: none;
  width: 20px;
  height: 20px;
  font-size: 10px;
  text-indent: -9999em;
  background: #0076eb;
  background: linear-gradient(to right, #0076eb 10%, rgba(0, 118, 235, 0) 42%);
  border-radius: 50%;
  transform: translateZ(0);
  animation: load 1.4s infinite linear;
}
.loader::before {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 50%;
  height: 50%;
  content: '';
  background: #0076eb;
  border-radius: 100% 0 0;
}
.loader::after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: none;
  width: 75%;
  height: 75%;
  content: '';
  background: white;
  border-radius: 50%;
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.blog-add-content-upload_description {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: #7a7a7a;
}
.blog-add-content-upload_add-img {
  display: none;
}
.blog-add-content-upload_preview {
  position: relative;
  margin-top: 13px;
  display: inline-block;
  height: 90px;
  text-align: center;
}
.blog-add-content-upload_preview > img {
  max-width: 100%;
  max-height: 100%;
}
.blog-add-content-upload_preview-btn {
  position: absolute;
  top: -8px;
  right: -8px;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: white;
  background: url('img/icn-close.svg') no-repeat center center #ff5050;
  border: 0;
  border-radius: 50%;
}
/* TODO: add class loading/error to .blog-add-content-upload */
.loading {
}
.error-img {
}
[class*='blog-add-content-upload'][class*='loading'] .loader {
  display: block;
}
[class*='blog-add-content-upload'][class*='loading'] .loader::before,
[class*='blog-add-content-upload'][class*='loading'] .loader::after {
  display: inline;
}
[class*='blog-add-content-upload'][class*='loading'] .blog-add-content-upload_input-wrap span:hover,
[class*='blog-add-content-upload'][class*='loading'] .blog-add-content-upload_input-wrap span:active {
  background-color: white;
}
[class*='blog-add-content-upload'][class*='loading'] .blog-add-content-upload_input-wrap span {
  color: transparent;
}
[class*='blog-add-content-upload'][class*='error-img'] .blog-add-content-upload_input-wrap span::after {
  display: block;
}
[class*='blog-add-content-upload'][class*='error-img'] .blog-add-content-upload_input-wrap span::before {
  display: inline;
}

/* TODO: add req class on blog-add-content_input, quill, blog-add-content-region on submit if field is empty */
.error,
.error-quill {
  display: none;
  font-weight: bold;
  color: #ff5050;
}
.req {
}
[class*='quill'][class*='req'] {
  border: 1px solid #ff5050;
  border-radius: 0 0 4px 4px;
}
[class*='quill'][class*='req'] ~ .error-quill {
  display: block;
}
.quill-editor_toolbar {
  border-radius: 4px 4px 0 0;
}
.tooltip-mobile {
  display: none;
}
.region-tooltip {
  width: auto;
}
.tooltip-quill {
  top: 42px;
}
.region-tooltip > span > div {
  width: 180px;
}
.region-tooltip span div:nth-child(2) {
  width: 290px;
}
.tag-container {
  margin-top: 8px;
  margin-right: 8px;
}
.tag-container button:hover::after,
.tag-container button:hover::before {
  background: #121212;
}

.blog-add-bside-tip-source_title-dekstop-icon {
  margin: -4px 12px 0 22px;
  display: inline-block;
  width: 26px;
  min-width: 26px;
  height: 24px;
  vertical-align: middle;
  background: url('img/important.svg') no-repeat center;
}
.login__error {
  cursor: pointer;
}
.blog-add__tooltip-quill > div > div:nth-child(2) {
  border: 1px solid #ccc;
  border-radius: 0 0 4px 4px;
}
.blog-add__tooltip-quill > div > div:nth-child(2):focus {
  border: 1px solid #121212;
  border-radius: 0 0 4px 4px;
}
.blog-credentials_input-tag-error > div > div:nth-child(2) {
  border: 1px solid #ff5050;
  border-radius: 0 0 4px 4px;
}
.blog-add-bside-tip_title-dekstop-icon {
  margin: 0 12px 0 21px;
  display: inline-block;
  width: 27px;
  min-width: 27px;
  height: 22px;
  background: url('img/cloud-text.svg') no-repeat center;
}
.blog-add-bside-tip-source_title-dekstop,
.blog-add-bside-tip_title-dekstop {
  display: flex;
}
.blog-add-bside-tip-source_title-dekstop-text,
.blog-add-bside-tip_title-dekstop-text {
  vertical-align: middle;
}

.page-block-content {
  padding-top: 8px;
  display: block;
  line-height: 1.43;
  border-top: 2px solid #182930;
}

.page-block-content_link {
  line-height: 1.43;
  text-decoration: none;
  color: #2b87db;
}

.page-block-content_link:hover {
  color: #df294e;
}

@media (max-width: 1024px) {
  .blog-add-content-wrap {
    padding-left: 20px;
    width: calc(66.66666666666666% - 0.5rem);
  }

  .blog-add-bside {
    width: calc(29.166666666666668% - 0.5rem);
  }

  .blog-add-content-upload_input-wrap span {
    padding: 9px 19px 11px 20px;
  }

  .blog-add-content-upload_description {
    margin-bottom: 10px;
  }

  .blog-add-content-upload_input-wrap {
    margin-top: 10px;
  }

  .blog-add-content-region,
  .blog-add-content-category {
    margin: 20px 0 0;
    display: block;
    width: 100%;
  }
  .region-tooltip > span,
  .region-tooltip div,
  .region-tooltip > span > div {
    margin-left: 0;
    width: 100%;
  }

  .region-tooltip div span:nth-child(2) {
    top: 16px;
    right: 16px;
  }

  .region-tooltip span div:nth-child(2) {
    width: 100%;
  }

  .region-tooltip {
    width: 100%;
  }

  .blog-add-content_input-tag,
  .blog-credentials_input-tag {
    height: 40px;
  }

  .blog-add-content_dropdown {
    top: 43px;
  }

  .blog-add-content_email-wrap,
  .blog-add-content_name-wrap {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .blog-add-content-wrap {
    padding-left: 20px;
    width: calc(100% - 0.5rem);
  }

  .blog-add-bside {
    width: calc(100% - 0.5rem);
  }
  [class*='blog-add-content-upload'][class*='error'] .blog-add-content-upload_input-wrap {
    height: 73px;
  }
  [class*='blog-add-content-upload'][class*='error'] .blog-add-content-upload_input-wrap span::after {
    top: 40px;
    left: 8px;
    max-height: 20px;
  }
  [class*='blog-add-content-upload'][class*='error'] .blog-add-content-upload_input-wrap span::before {
    left: 3px;
    bottom: 6px;
  }
  .blog-add-content-upload {
    margin-bottom: 20px;
  }
  .blog-add-bside {
    margin: 0;
    padding: 0;
  }
  .blog-add-bside-realtor-demand {
    margin: 0;
    max-width: 100%;
    border-bottom: 1px solid rgba(21, 34, 66, 0.1);
  }
  .blog-add-bside-tip,
  .blog-add-bside-tip-source {
    position: relative;
    margin: 0;
    float: none;
    overflow-y: hidden;
    max-width: 100%;
    max-height: 61px;
  }

  .blog-add__arrow-bottom {
    position: absolute;
    top: 0;
    right: 21px;
    bottom: 0;
    margin: auto;
    display: block;
    width: 8px;
    height: 5px;
    background: url('img/arrow-little.svg') no-repeat center;
  }
  .blog-add-bside-tip {
    margin-top: 0;
  }
  .blog-add-content-wrap {
    margin: 0;
    padding: 0 20px;
  }
  .blog-add-content {
    padding: 0;
    border: 0;
    border-top: 2px solid #182930;
  }
  .blog-add-content_main-heading {
    font-size: 18px;
  }
  .blog-add-content_input-wrap-mobile {
    margin: 20px 0;
    display: block;
  }
  .blog-add-content_select {
    margin: 10px 0;
    width: 100%;
    height: 40px;
  }
  h3.blog-add-bside-tip_title-mobile,
  h3.blog-add-bside-tip-source_title-mobile {
    position: relative;
    padding: 17px 0 21px;
    display: block;
    cursor: pointer;
  }
  .blog-add-bside-tip_title-dekstop,
  .blog-add-bside-tip-source_title-dekstop {
    display: none;
  }
  .blog-add-bside-tip_visible-mobile,
  .blog-add-bside-tip-source_visible-mobile {
    max-height: none;
  }

  .blog-add-bside-tip_visible-mobile .blog-add__arrow-bottom,
  .blog-add-bside-tip-source_visible-mobile .blog-add__arrow-bottom {
    transform: rotate(180deg);
  }
  .blog-add-content_main-heading {
    padding-left: 0;
  }
  .blog-add-bside {
    margin-top: 40px;
  }
  .blog-add-bside section:nth-child(2) {
    margin-top: 0;
  }
  .blog-add-content_name-wrap,
  .blog-add-content_email-wrap {
    margin: 20px 0;
    width: 100%;
  }
  .blog-add-content_name-wrap {
    margin: 0;
  }
  .blog-add-content_email,
  .blog-add-content_name {
    margin-top: 0;
    display: inline-block;
  }
  .tooltip-mobile {
    display: block;
  }
  .tooltip-dekstop {
    display: none;
  }
  .tooltip-quill {
    top: 100%;
  }

  .tag-container {
    margin-top: 11px;
  }

  .blog-add-content-footer {
    padding-top: 0;
  }

  .blog-add-content {
    padding: 0 30px 40px;
    border: solid 1px #e4e4e4;
    border-top: 2px solid #182930;
  }

  .custom-row {
    margin-bottom: 0;
  }

  .blog-add-bside-tip_title-tablet-icon {
    vertical-align: middle;
  }

  .blog-add-bside-tip,
  .blog-add-bside-tip-source {
    border-bottom: 0;
  }

  .blog-add-content-upload_input-wrap {
    margin-top: 10px;
  }

  .blog-add-content-upload_input-wrap span {
    padding: 9px 19px 11px 20px;
  }

  .tooltip-tablet {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 2px 0 3px;
    width: 100%;
    max-width: none;
    min-height: 25px;
    text-align: center;
    border-radius: 3px;
    transform: translate(0);
  }
  .tooltip-tablet::before {
    display: none;
  }
}

@media (max-width: 639px) {
  .blog-add-content_main-heading {
    margin: 13px 0 15px;
    display: none;
  }

  .blog-add-bside {
    margin-top: 30px;
  }

  .blog-add-content {
    padding: 0;
    border: 0;
    border-top: 2px solid #182930;
  }

  .blog-add-content {
    border-top: 0;
  }
}
