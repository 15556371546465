.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--unit_4);
  height: 100%;
}

.title {
  text-decoration: none;
}

.title:hover * {
  color: var(--fill_primary_hover_100);
}

.activity-panel {
  display: flex;
  align-items: center;
}

.activity-panel > * + * {
  margin-left: var(--unit_4);
}

.views,
.comments {
  display: flex;
  align-items: center;
  font-size: var(--fontSize_16px);
  line-height: 19px;
  color: var(--gray60_100);
}

.views > svg,
.comments > svg {
  margin-right: 6px;
}

.image-wrapper {
  display: flex;
  width: 100%;
}
