.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--unit_4);
}

.content-wrapper > *:first-child {
  margin-bottom: calc(var(--unit_2) * -1);
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  border-radius: 50%;
}

.footnote-wrapper {
  margin-top: var(--unit_6);
}

.footnote-wrapper > * > *:not(:first-child):last-child {
  margin-left: auto;
}

.headnote {
  display: flex;
  align-items: center;
  gap: var(--unit_4);
}
