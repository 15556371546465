.wrapper {
  height: 40px;
}

.skeleton-wrapper {
  display: flex;
}

.skeleton-wrapper > * + * {
  margin-left: var(--unit_2);
}

@media screen and (min-width: 641px) {
  .wrapper {
    height: 28px;
  }
}
