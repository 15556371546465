.wrapper {
  padding-top: var(--unit_4);
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit_2) var(--unit_3);
}

.wrapper > * {
  margin: var(--unit_1) 0;
}

@media screen and (min-width: 641px) {
  .wrapper {
    padding-top: var(--unit_5);
  }
}
