/* PostParentsLayout */
.wrapper > *:nth-child(1) {
  margin-bottom: var(--unit_3);
}

/* AuthorArticlesNewContainer */
.wrapper > *:nth-child(5) {
  margin-top: var(--unit_7);
  margin-bottom: var(--unit_7);
}

@media screen and (min-width: 641px) {
  /* PostParentsLayout */
  .wrapper > *:nth-child(1) {
    margin-bottom: var(--unit_4);
  }
}
