.arrows {
  position: absolute;
  top: 50%;
  display: flex;
  width: 100%;
  opacity: 0;
  justify-content: space-between;
  transition: opacity 0.2s ease-in-out;
  transform: translateY(-50%);
}

.wrapper:hover .arrows,
.fullscreen-overlay:hover .arrows,
.wrapper:hover .fullscreen-button,
.fullscreen-overlay:hover .close-button {
  opacity: 1;
}

.arrow,
.fullscreen-button,
.close-button {
  width: 40px;
  height: 40px;
  line-height: 0;
  cursor: pointer;
  background: var(--light-background-tertiary);
  border: none;
  border-radius: 50%;
  outline: none;
}

.arrow-prev {
  margin-left: var(--unit_4);
}

.arrow-next {
  margin-right: var(--unit_4);
}

.fullscreen-button {
  position: absolute;
  top: var(--unit_4);
  right: var(--unit_4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.close-button {
  position: absolute;
  top: var(--unit_4);
  right: var(--unit_8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.description {
  margin-top: var(--unit_2);
  overflow: hidden;
  height: auto;
  transition: height 0.2s ease-in-out;
}

.description-inner {
  display: flex;
  flex-direction: column;
  gap: var(--unit_1);
}

.description-inner > * {
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
}

.fullscreen-overlay {
  position: fixed;
  z-index: var(--zIndex_modal);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 60px var(--unit_4) var(--unit_4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--black_100);
}

.fullscreen-overlay > *:nth-child(1) {
  border-color: transparent;
}

.fullscreen-overlay > *:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* post content clearfix */
.wrapper img {
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  height: 100% !important;
}

/* post content clearfix */
.wrapper li::before {
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  content: '' !important;
}
