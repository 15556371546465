.wrapper > * {
  font-weight: bold;
}

/* расстояние между иконкой и каунтом */
/* stylelint-disable-next-line selector-max-compound-selectors */
.wrapper > * > * + * {
  margin-left: var(--unit_2);
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.icon-wrapper._clickable {
  cursor: pointer;
}

.icon-wrapper > svg {
  color: var(--light-text-seconary);
}
