.image-wrapper {
  position: relative;
  display: flex;
}

.image-wrapper > div {
  /* stylelint-disable-next-line declaration-no-important */
  border-top-left-radius: 3px !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-top-right-radius: 3px !important;
}

.adv-label {
  position: absolute;
  z-index: 1;
  top: var(--unit_6);
  left: var(--unit_6);
  padding: var(--unit_0) var(--unit_2);
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  color: var(--white_100);
  background: var(--black_icons_60);
  border-radius: var(--unit_1);
}

.content-wrapper {
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-wrapper._has-image {
  height: calc(100% - 145px);
}

.title {
  display: block;
  font-size: var(--fontSize_18px);
  font-weight: bold;
  line-height: var(--lineHeight_24px);
  text-decoration: none;
  letter-spacing: -0.5px;
  color: var(--black_100);
}

.description {
  margin-bottom: var(--unit_3);
  font-size: var(--fontSize_14px);
  font-weight: bold;
  line-height: var(--lineHeight_20px);
  color: var(--black_100);
}

.site-name {
  margin-bottom: var(--unit_3);
  font-size: var(--fontSize_16px);
  line-height: 19px;
  color: var(--gray60_100);
}

.footnote {
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  mask: linear-gradient(rgba(255, 255, 255, 0.31), black) center top / 100% 14px no-repeat,
    linear-gradient(black, black) center 14px / 100% calc(100% - 14px) no-repeat,
    linear-gradient(black, rgba(255, 255, 255, 0)) center bottom / 100% 14px no-repeat;
  color: var(--gray40_100);
}

.footnote._rows-4 {
  max-height: calc(var(--lineHeight_16px) * 4);
}

.footnote._rows-5 {
  max-height: calc(var(--lineHeight_16px) * 5);
}

.footnote._rows-6 {
  max-height: calc(var(--lineHeight_16px) * 6);
}

.footnote._rows-7 {
  max-height: calc(var(--lineHeight_16px) * 7);
}

@media screen and (min-width: 641px) {
  .content-wrapper {
    padding: var(--unit_6);
  }

  .content-wrapper._has-image {
    height: calc(100% - 180px);
  }

  .title {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    overflow: hidden;
    max-height: calc(var(--lineHeight_24px) * 4);
    line-height: var(--lineHeight_24px);
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .description {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    overflow: hidden;
    max-height: calc(var(--lineHeight_20px) * 2);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
