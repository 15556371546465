.wrapper {
  display: flex;
  align-items: center;
}

.wrapper > *:nth-child(3) {
  margin-left: var(--unit_6);
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  border-radius: 50%;
}

.content-wrapper {
  margin-left: var(--unit_9);
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--unit_4);
}

.headnote {
  display: flex;
  align-items: center;
  gap: var(--unit_4);
}

@media screen and (min-width: 1025px) {
  .content-wrapper {
    margin-left: var(--unit_10);
  }
}
