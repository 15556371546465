.wrapper {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 641px) {
  .wrapper {
    flex-direction: row;
    gap: var(--unit_5);
  }

  .body {
    flex: 1 1 auto;
    width: 67%;
  }

  .sidebar {
    width: 33%;
    min-width: 300px;
    max-width: 356px;
  }

  .sidebar > * + * {
    margin-top: var(--unit_2);
  }
}
