.advice-list-article-author {
}
.advice-list-article-author_avatar {
  margin-right: 10px;
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}
.advice-list-article-author_avatar-small {
  width: 32px;
  height: 32px;
}
.advice-list-article-author_avatar-outer {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  max-height: 0;
}
.advice-list-article-author_avatar-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.advice-list-article-author_avatar-img {
  height: 100%;
  background: #e4e4e4 url('img/user-default.svg') no-repeat 50% 50%;
  background-size: contain;
  border-radius: 50%;
}
.advice-list-article-author_avatar-img-default {
  background-size: initial;
  border: solid 1px #e4e4e4;
}
.advice-list-article-author_info {
  display: inline-block;
  max-width: calc(100% - 49px);
  min-height: 40px;
  font-size: 12px;
  vertical-align: middle;
}
.advice-list-article-author_info.small {
  min-height: inherit;
}
.advice-list-article-author_info-name {
  margin: 0;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-decoration: none;
  color: #121212;
}

a.advice-list-article-author_info-name:hover {
  cursor: pointer;
  color: #e84260;
}

.advice-list-article-author_info-name.small {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
}
.advice-list-article-author_info-descr {
  padding-top: 2px;
  font-size: 12px;
  line-height: 1.33;
  color: #7a7a7a;
}
.advice-list-article-author_rating {
  display: inline-block;
}
.advice-list-article-author_rating.small {
  min-height: inherit;
}
.advice-list-article-author_rating > svg:first-child {
  margin: 0 8px;
}

.user-trust {
  margin-left: 8px;
  display: inline-block;
  width: 13px;
  height: 18px;
  vertical-align: middle;
}

.user-pro {
  margin-left: 7px;
  display: inline-block;
  width: 35px;
  height: 15px;
  vertical-align: middle;
  background-image: url('img/pro.svg');
}

.advice-list-article-author_info-empty-company,
.advice-list-article-author_rating-empty-company {
  padding-top: 0;
  min-height: auto;
  vertical-align: initial;
}

.advice-list-article-author_rating-empty-company {
  position: relative;
}

@media screen and (max-width: 768px) {
  .advice-list-article-author_info-mobile {
    padding-top: 0;
    min-height: auto;
    vertical-align: initial;
  }

  .advice-list-article-author_info-descr {
    display: none;
  }

  .advice-list-article-author_avatar {
    width: 32px;
    height: 32px;
  }
}
