.redaction {
    font-family: Lato, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
}

.redaction__text {
    display: block;
    font-weight: 900;
    line-height: 2;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 10px;
}

.redaction__link {
    color: #2371d2;
    text-decoration: none;
}

.redaction__link:hover {
    color: #df294e;
}
