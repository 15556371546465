.rating-table {
    padding: 0 10px;
}
.rating-table-header {
    padding-top: 14px;
    margin-bottom: 17px;
}
.rating-table-header-rules {
    margin-bottom: 19px;
}
    .rating-table-header_heading {
        text-transform: uppercase;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 1px;
        display: inline-block;
        margin: 0;
        width: calc(100% - 182px);
    }
    .rating-table-header_rules {
        line-height: 1.14;
        font-size: 14px;
        display: inline-block;
        border-bottom: 1px dotted rgba(18,18,18, .5);
        cursor: pointer;
    }
    .rating-table-header_rules-icon {
        width: 8px;
        height: 6px;
        background: url("img/icn-filters-more-arrow-small.svg");
        display: inline-block;
        margin-left: 2px;
    }
    .rating-table-header_rules-icon-open {
        transform: rotate(180deg);
    }
.tip-points-block {
    border-radius: 2px;
    background-color: #f4f4f4;
    padding: 21px 20px 32px 20px;
}
    .tip-points-block-col {
        padding-right: 5px;
        margin-right: 75px;
    }
    .tip-points-block h3 {
        margin-top: 0;
        margin-bottom: 14px;
        font-size: 14px;
        line-height: 20px;
    }
    .tip-points-block_name {
        display: inline-block;
        width: calc(100% - 25px);
        margin-bottom: 14px;
    }
    .tip-points-block_popular {
        margin-bottom: 0;
    }
    .tip-points-block_value {

    }
  /*  .tip-points-block-col .tip-points-block_name:first-child {
        margin-bottom: 14px;
    }*/
    .tip-points-block_tip {
        color: #7a7a7a;
        margin: 0;
        margin-bottom: 14px;
    }
.rating-table-select-wrap {
    margin-top: 17px;
    padding-bottom: 31px;
}

/* TABLE */
.table-wrap {
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    min-width: 788px;
}

.table-heading-1 {
    height: 20px;
}

.table-heading-1_cell {
    padding-left: 90px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: left;
    color: #121212;
}

.table-heading-2 {
    height: 43px;
    -webkit-box-shadow: 0 2px 0 0 rgba(24,41,48,1);
    -moz-box-shadow: 0 2px 0 0 rgba(24,41,48,1);
    box-shadow: 0 2px 0 0 rgba(24,41,48,1);
}

    .table-heading_cell {
        font-weight: bold;
        text-align: left;
        padding-bottom: 21px;
    }
    .table-heading_cell:first-child {
        padding-left: 10px;
    }
    .table-heading_cell:last-child {
        padding-right: 10px;
    }
  /*  .table-heading_cell:nth-child(4) {
        color: #e84260;
        padding-right: 12px;
        background: url("./img/icn-sort.svg") right center no-repeat;
    }*/
    .table-heading_cell:nth-child(4),
    .table-heading_cell:nth-child(5),
    .table-heading_cell:nth-child(6),
    .table-heading_cell:nth-child(7),
    .table-heading_cell:nth-child(8),
    .table-heading_cell:nth-child(9) {
        color: #7a7a7a;
    }
    .table-heading_cell:nth-child(3),
    .table-heading_cell:nth-child(4),
    .table-heading_cell:nth-child(5),
    .table-heading_cell:nth-child(6),
    .table-heading_cell:nth-child(7),
    .table-heading_cell:nth-child(8),
    .table-heading_cell:nth-child(9) {
        text-align: right;
        padding-left: 0;
    },
    .table-heading_cell:nth-child(5) {
        width: 93px;
    }
    .table-heading_cell:nth-child(7),
    .table-heading_cell:nth-child(9) {
        width: 103px;
    }
    .table-heading_cell:nth-child(4),
    .table-heading_cell:nth-child(6),
    .table-heading_cell:nth-child(8) {
        width: 115px;
    }
    .table-heading_cell:not(.table-row_cell-not-hover):hover {
        cursor: pointer;
        color: #e84260;
    }
    /*TODO: add 'active' class on .table-heading_cell onclick (toggle)*/
    .table-heading_cell.active.down {
        padding-right: 12px;
        background: url("img/icn-sort--black-down.svg") right top no-repeat;
        background-position-y: 10px;
    }
    .table-heading_cell.active.up {
        padding-right: 12px;
        background: url("img/icn-sort--black.svg") right top no-repeat;
        background-position-y: 10px;
    }
    .table-heading_cell.active:hover {
        color: #e84260;
    }
    .table-heading_cell.active.down:hover{
        background: url("img/icn-sort.svg") right top no-repeat;
        background-position-y: 10px;
    }
    .table-heading_cell.active.up:hover {
        background: url("img/icn-sort-down.svg") right top no-repeat;
        background-position-y: 10px;
    }
    .table-heading_cell.active.down:active {
        background: url("img/icn-sort-down.svg") right top no-repeat;
        background-position-y: 10px;
    }
    .table-heading_cell.active.up:active {
        background: url("img/icn-sort.svg") right top no-repeat;
        background-position-y: 10px;
    }
    .active {

    }
    .table-row {
        height: 52px;
    }
    /*TODO: add 'my-rating' class on current .table-row*/
    [class*='table-row'][class*='my-rating'] {
        background-color: rgba(255,157,0, .1);
    }
    .my-rating {

    }
    .table-row-link {
        cursor: pointer;
    }
    .table-row:hover {
        background-color: rgba(43,135,219, .1);
    }
    .table-row:hover .author-container-specialist {
        color: #e84260;
    }
    .table-row_cell {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .table-row_cell:first-child {
        padding-left: 10px;
    }
    .table-row_cell:last-child {
        padding-right: 10px;
    }
    .table-row_cell:nth-child(3),
    .table-row_cell:nth-child(4),
    .table-row_cell:nth-child(5),
    .table-row_cell:nth-child(6),
    .table-row_cell:nth-child(7),
    .table-row_cell:nth-child(8),
    .table-row_cell:nth-child(9) {
        text-align: right;
    }
    .table-row_cell:nth-child(1),
    .table-row_cell:nth-child(2),
    .table-row_cell:nth-child(3) {
        font-weight: bold;
    }
    .table-row_cell .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }
    .table-row_cell .avatar-outer {
        position: relative;
        max-height: 0;
        padding-bottom: 100%;
        overflow: hidden;
    }
    .table-row_cell .avatar-inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .table-row_cell .avatar-img {
        background-image: url('img/no-image.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 100%;
    }
    .username {
        margin-right: 8px;
        margin-left: 8px;
    }
    .badge-honest,
    .badge-pro {
        vertical-align: middle;
    }
    .badge-honest {
        margin-right: 7px;
    }

    .rating-filter {
      margin-right: 12px;
      display: inline-block;
    }
@media screen and (max-width: 1024px) {
    .rating-table-header_heading {
        width: 100%;
    }
}
