.main-gallery {
  position: relative;
  margin-bottom: var(--unit_2);
  overflow: hidden;
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
}

/* post content clearfix */
/* stylelint-disable-next-line selector-max-compound-selectors */
.main-gallery ul:first-child li img {
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  height: 100% !important;
}
