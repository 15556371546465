.wrapper {
  cursor: pointer;
  border: 1px solid var(--gray10_100);
  border-radius: var(--unit_1);
  transition: box-shadow 100ms ease-in-out;
}

.wrapper:hover {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
}
