.wrapper {
  margin-bottom: var(--unit_5);
  display: flex;
  flex-direction: column;
  gap: var(--unit_5);
}

@media screen and (min-width: 641px) {
  .wrapper {
    margin-bottom: var(--unit_6);
    flex-direction: row;
    align-items: stretch;
  }

  .wrapper > * {
    flex: 1 1 50%;
  }
}
