.list-blocked {
    margin-top: 24px;
}

.list-blocked_title {
    display: block;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.27;
    margin-bottom: 8px;
}

.list-blocked_text {
    display: block;
    line-height: 1.43;
    margin-bottom: 8px;
}

.list-blocked_link {
    text-decoration: none;
    color: #2b87db;
    line-height: 1.43;
}

.list-blocked_link:hover {
    color: #df294e;
}

@media screen and (max-width: 639px) {
    .list-blocked {
        margin-top: 16px;
        margin-bottom: -10px;
    }

    .list-blocked_title {
        font-size: 18px;
        line-height: 1.33;
    }

}
