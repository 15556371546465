.wrapper > *:nth-child(1),
.wrapper > *:nth-child(2) {
  margin-bottom: var(--unit_5);
}

.wrapper > *:nth-child(3) {
  margin-top: var(--unit_5);
  margin-bottom: var(--unit_4);
}

@media screen and (min-width: 641px) {
  .wrapper > *:nth-child(1),
  .wrapper > *:nth-child(2) {
    margin-bottom: var(--unit_6);
  }

  .wrapper > *:nth-child(3) {
    margin-top: var(--unit_6);
    margin-bottom: var(--unit_6);
  }
}
