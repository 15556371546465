.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  color: var(--black_100);
}

.wrapper > * + * {
  margin-left: var(--unit_2);
}

.name > * + *,
.transport > * + * {
  margin-left: var(--unit_1);
}

.name,
.transport {
  display: flex;
  align-items: center;
}

.name svg,
.transport svg {
  width: var(--fontSize_16px);
  min-width: var(--fontSize_16px);
  height: var(--fontSize_16px);
  min-height: var(--fontSize_16px);
}

.name svg {
  color: inherit;
}

.name._ellipsis {
  max-width: 57%;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.ellipsis {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
