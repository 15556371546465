.read-topic {
    background-color: #f4f4f4;
    padding: 37px 0 0;
    display: none;
    width: 100%;
}

.read-topic-dekstop {
    display: block;
}

.read-topic__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 21px;
    border-bottom: 2px solid #182930;
    letter-spacing: 1px;
}

.read-topic__header_text {
    margin: 0;
    font-size: 22px;
    line-height: 1.27;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .read-topic {
        padding-top: 21px;
        padding-bottom: 0;
    }

    .read-topic__content {
        padding: 0 20px;
    }

    .read-topic-dekstop {
        display: none;
    }

    .read-topic-mobile {
        display: block;
    }

    .read-topic__header {
        padding-bottom: 17px;
    }

    .read-topic__header_text {
        font-size: 14px;
        line-height: 1.43;
    }

    .list-content {
        margin-bottom: 20px;
    }
}
