.wrapper {
  height: 100%;
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  background-size: cover;
  border-radius: var(--unit_2);
}

.content-wrapper {
  padding: var(--unit_4);
  display: flex;
  height: 100%;
  align-items: center;
  min-height: 272px;
  color: var(--black_100);
}

.subscribed-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.subscribed-wrapper > svg {
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
}

.subscribed-wrapper > * + * {
  margin-top: var(--unit_2);
}

.subscribed-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}

.subscribed-content > * {
  flex: 0 0 auto;
}

.subscribed-content > *:first-child {
  margin-right: var(--unit_1);
}

.unsubscribed-wrapper {
  width: 100%;
}

.unsubscribed-wrapper > *:nth-child(1) {
  margin-bottom: var(--unit_4);
}

.fields-wrapper {
  margin-bottom: var(--unit_4);
}

.input-wrapper {
  margin-bottom: var(--unit_2);
}

.error-wrapper {
  overflow: hidden;
  max-height: 0;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--error_100);
  transition: max-height 300ms ease;
}

.error-wrapper._active {
  max-height: 32px;
}

.error {
  margin-top: 3px;
}

.categories-wrapper {
  padding-bottom: var(--unit_2);
}

.options-content > *:first-child {
  margin-bottom: var(--unit_4);
}

.options-trigger {
  margin-bottom: var(--unit_4);
  padding: 10px 0 var(--unit_3) 0;
  display: inline-flex;
  align-items: center;
  gap: var(--unit_2);
  flex-wrap: nowrap;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  cursor: pointer;
  color: var(--primary_100);
}

.options-trigger > svg {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.description {
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
}

.description a {
  text-decoration: none;
  color: var(--primary_100);
}

@media screen and (min-width: 641px) {
  .wrapper {
    display: flex;
    align-items: stretch;
    overflow: hidden;
    justify-content: space-between;
  }

  .subscribed-wrapper {
    min-width: 312px;
    height: auto;
  }

  .subscribed-content {
    max-width: none;
  }

  .content-wrapper {
    padding: var(--unit_6);
    min-height: auto;
  }

  .fields-wrapper {
    display: flex;
    flex-direction: row;
  }

  .fields-wrapper > *:nth-child(2) {
    margin-left: var(--unit_2);
  }

  .input-wrapper {
    margin-bottom: 0;
    width: 316px;
  }

  .options-content > *:first-child {
    width: 244px;
  }

  .categories-wrapper {
    columns: 2;
    column-gap: var(--unit_4);
    max-width: 504px;
  }

  .categories-wrapper > * {
    margin-bottom: var(--unit_2);
    width: 244px;
  }

  .man-wrapper {
    margin-top: auto;
    display: flex;
    min-width: 400px;
    min-height: 192px;
  }

  .dog-and-clouds-wrapper {
    margin-top: auto;
    display: flex;
    min-width: 348px;
    min-height: 192px;
  }
}

@media screen and (min-width: 1025px) {
  .content-wrapper {
    padding: var(--unit_6) 0 var(--unit_6) var(--unit_10);
  }
}
