.wrapper {
  position: absolute;
  z-index: var(--zIndex_modal);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  background: var(--white_100);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.header-wrapper {
  padding: 0 var(--unit_4);
  width: 100%;
}

.header {
  padding: var(--unit_6) 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--superblack_100);
}

.header > svg {
  min-width: 16px;
  min-height: 16px;
}

.input-wrapper {
  margin-right: var(--unit_1);
  width: 100%;
}

.input-wrapper > * {
  /* stylelint-disable-next-line declaration-no-important */
  border: none !important;
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
}

.content-wrapper {
  padding: 0 var(--unit_4);
  overflow-y: auto;
  height: 100%;
}

.content {
  height: 100%;
}

.footer {
  text-align: center;
}

@media screen and (min-width: 1025px) {
  .header-wrapper {
    margin: 0 auto;
    padding: 0 240px 0 calc(240px + 0.5rem);
    max-width: 1376px;
  }

  .header {
    max-width: 880px;
  }

  .content-wrapper {
    padding: 0;
  }

  .content {
    margin: 0 auto;
    padding: 0 240px 0 calc(240px + 0.5rem);
    max-width: 1376px;
  }

  .content > * {
    max-width: 880px;
  }
}
