.wrapper._shown,
.wrapper._hiding {
  position: fixed;
  z-index: calc(var(--zIndex_modal) + 1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapper._shown > .overlay {
  opacity: 0.4;
}

.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--superblack_100);
  opacity: 0;
  transition-timing-function: ease-in-out;
  transition-property: opacity;
}

.wrapper._shown > .modal {
  max-height: 100vh;
}

.modal {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  max-height: 0;
  transition-timing-function: ease-in-out;
  transition-property: max-height;
}
