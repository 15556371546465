.wrapper {
  position: relative;
  display: block;
}

.link {
  padding: 0 var(--unit_4);
  display: flex;
  align-items: center;
  height: 50px;
  font-size: var(--fontSize_16px);
  font-weight: bold;
  line-height: var(--lineHeight_20px);
  text-decoration: none;
  color: var(--white_100);
  background-color: var(--black_100);
}

.link._active {
  background-color: var(--gray60_100);
}

.link > .icon-after-wrapper {
  flex-shrink: 0;
  margin-left: auto;
  padding-left: var(--unit_2);
  line-height: 0;
}

.link > * + * {
  margin-left: 10px;
}

.icon-chevron-wrapper,
.icon-plus-wrapper {
  margin-left: auto;
  line-height: 0;
}

.icon-chevron-wrapper > svg,
.icon-plus-wrapper > svg {
  color: var(--white_100);
  transition: transform 0.5s ease-in-out;
}

/* Анимация контрола у списка */
.icon-chevron-wrapper._rotate > svg {
  transition: transform 0.3s ease-in-out;
  transform: scaleY(-1);
}

/* Анимация контрола у списка */
.icon-plus-wrapper._rotate > svg {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-45deg);
}

/* Защита от длинных названий - обрезаем и добавляем "..." */
.label-wrapper {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Анимация раскрытия/закрытия списка */
.children-wrapper {
  overflow: hidden;
  width: 100%;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.children-wrapper._shown {
  max-height: 1000px;
  transition: max-height 0.5s ease;
}

/* Список может иметь абсолютное позиционирование */
.children-wrapper._absolute {
  position: absolute;
  z-index: var(--zIndex_modal);
}

/* Анимация вспышки при клике на пункте */
.wrapper._is-flash .children-wrapper,
.wrapper._is-flash .link:not(._active) {
  animation: flash 0.5s ease-in forwards;
}

@keyframes flash {
  95% {
    background-color: var(--gray60_100);
  }
  100% {
    background-color: var(--black_100);
  }
}
