.unsubscribe {
    text-align: center;
    background: #f4f4f4;
    padding: 40px 0 60px;
}

.unsubscribe-content {
    background: #fff;
    width: 992px;
    padding: 40px 0 60px;
    margin: auto;
    border: solid 1px #e4e4e4;
}

.unsubscribe_title {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.36;
    margin: 0 0 12px;
}

.unsubscribe_info {
    font-size: 16px;
    line-height: 1.38;
    text-align: center;
    margin: 0 0 20px;
}

.unsubscribe_email {
    font-weight: bold;
}

@media screen and (max-width: 1024px) {
    .unsubscribe {
        padding: 20px 0;
    }

    .unsubscribe-content {
        width: calc(100% - 20px);
        max-width: 728px;
    }
}

@media screen and (max-width: 639px) {
    .unsubscribe {
        padding: 20px 16px;
        margin: 0;
    }

    .unsubscribe-content {
        padding: 24px 16px 30px;
    }

    .unsubscribe_title {
        font-size: 18px;
        line-height: 1.33;
    }
}
