.wrapper {
  position: relative;
  padding: var(--unit_4) var(--unit_12) var(--unit_4) var(--unit_4);
  min-height: 55px;
  background: var(--white_100);
  border-bottom: 1px solid var(--gray10_100);
}

.content {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  font-size: var(--fontSize_16px);
  font-weight: bold;
  line-height: var(--lineHeight_22px);
  color: var(--black_100);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.close-button-wrapper {
  position: absolute;
  top: 3px;
  right: 3px;
}

.close-button-wrapper > * {
  padding: 14px;
}
