.aside {
  margin-right: 0;
  display: flex;
  justify-content: center;
  float: right;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .aside {
    margin: auto;
    padding: 20px 20px 13px;
    float: none;
    width: 100%;
    background-color: #f4f4f4;
  }
}
