.wrapper {
  margin: var(--unit_6) 0 var(--unit_4);
}

.wrapper > *::first-letter {
  text-transform: uppercase;
}

@media screen and (min-width: 1025px) {
  .wrapper {
    margin: var(--unit_10) 0 var(--unit_6);
  }
}
