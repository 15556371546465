.wrapper {
  padding: var(--unit_4) var(--unit_6);
  color: var(--black_100);
}

.item {
  position: relative;
  padding-left: var(--unit_6);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
}

.item + .item {
  margin-top: var(--unit_4);
}

.item::before {
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  content: '';
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.733 4.68l-8.067 8.688-5.299-4.327 1.265-1.549L6.478 10l6.79-6.68 1.466 1.36z' fill='%233AC500'/%3E%3C/svg%3E")
    center no-repeat;
}
