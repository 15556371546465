.wrapper {
  padding: var(--unit_1) var(--unit_2);
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: var(--unit_1);
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  white-space: nowrap;
  color: var(--decorative-accent-purple);
  background-color: var(--decorative-accent-lavender);
  border-radius: 4px;
}

.wrapper > svg {
  color: var(--decorative-accent-purple);
}
