.subscribe-list {
    background: #f4f4f4;
    padding: 20px 20px 0;
    border-bottom: 1px solid #e4e4e4;
}

.subscribe-list__header {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 22px;
}

.subscribe-list__header:hover .subscribe-list__header_text-dekstop,
.subscribe-list__header:hover .subscribe-list__header_text-mobile {
    color: #e84260;
}

.subscribe-list__header:hover .subscribe-list__header_arrow-icon {
    background: url(img/subscribe-list-arrow-hover.svg) no-repeat center;
}

.subscribe-list__header:hover .subscribe-list__header_mail-icon {
    background: url(img/subscribe-email-hover.svg);
}

.subscribe-list__header_mail-icon {
    display: inline-block;
    width: 27px;
    height: 26px;
    background: url(img/subscribe-email.svg);
    margin-right: 12px;
    vertical-align: middle;
}

.subscribe-list__header_text-dekstop {
    display: inline-block;
    vertical-align: middle;
}

.subscribe-list__header_text-mobile {
    display: none;
}

.subscribe-list__header_arrow-icon {
    display: inline-block;
    width: 8px;
    height: 6px;
    margin-left: 5px;
    background: url(img/subscribe-list-arrow.svg) no-repeat center;
    vertical-align: middle;
}

.subscribe-list__header_arrow-icon-open-content {
    transform: rotate(180deg);
}

.subscribe-list__content {
    display: block;
    padding-bottom: 12px;
}

.subscribe-list__content_email {
    display: inline-block;
    width: 292px;
    height: 28px;
    padding: 3px 8px 5px;
    line-height: 1.43;
    border: solid 1px #c9c9c9;
    border-radius: 4px;
    outline: none;
    font-family: Lato, Arial, sans-serif;
    font-size: 14px;
}

.subscribe-list__content_email-error {
    display: inline-block !important;
    width: 292px;
    margin-right: 12px;
}

.subscribe-list__content_email::placeholder {
    color: #999;
}

.subscribe-list__content_email:focus {
    border: solid 1px #121212;
}

.subscribe-list__content_button {
    display: inline-block;
    background: #e84260;
    border: 1px solid #e84260;
    outline: none;
    width: 117px;
    height: 28px;
    border-radius: 42px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    font-family: Lato, Arial, sans-serif;
    text-align: center;
    padding: 3px 0 5px;
    vertical-align: middle;
    margin-left: 12px;
}

.subscribe-list__content_button:hover {
    background-color: #bf3755;
}

.subscribe-list__success,
.subscribe-list__error {
    line-height: 1.29;
    color: #121212;
    margin-top: 24px;
}

.subscribe-list__success_header,
.subscribe-list__error_header {
    font-weight: bold;
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 5px;
}

.subscribe-list__error_header {
    margin-bottom: 8px;
}

.subscribe-list__success_header::before {
    content: '';
    display: block;
    position: absolute;
    background: url(img/subscribe-done-icon.svg);
    left: 0;
    top: 4px;
    width: 14px;
    height: 10px;
}

.subscribe-list__error_header::before {
    content: '';
    display: block;
    position: absolute;
    background: url(img/subscribe-err-icon.svg) no-repeat center;
    left: 0;
    top: 2px;
    width: 14px;
    height: 14px;
}

.subscribe-list__success_text {
    display: block;
    padding-left: 22px;
}

.subscribe-list__success_email {
    color: #e84260;
}

.subscribe-list__wrap-regions_dropdown-wrap {
    display: inline-block;
}

.subscribe-list__wrap-categories-wrap {
    margin: 20px 0 12px;
}

.subscribe-list__wrap-categories-wrap_checkbox-container-wrap {
    display: inline-block;
    width: 50%;
    margin-bottom: 12px;
}

.subscribe-list__wrap-regions_dropdown-wrap {
    background: #fff;
    border-radius: 42px;
    max-width: calc(100% - 53px);
    margin-left: 8px;
}

.subscribe-list__wrap-regions_dropdown-wrap > span {
    width: 100%
}

.subscribe-list__error-question,
.subscribe-list__error_header {
    margin-top: 0;
}

.tooltip-email {
    width: auto;
}

.license {
    padding: 0 0 30px;
    font-size: 12px;
    line-height: 18px;
    font-family: Lato, Arial, sans-serif;
}

.license .link {
    text-decoration: none;
    color: #2b87db;
}

@media screen and (max-width: 639px) {
    .subscribe-list {
        padding: 0;
        margin-left: -20px;
        width: calc(100% + 47px);
        border-top: 1px solid #e4e4e4;
        margin-top: -1px;
    }

    .subscribe-list__content_email {
        width: 100%;
        height: 40px;
        padding: 9px 12px 11px;
    }

    .subscribe-list__content_email-error {
        width: 100%;
    }

    .subscribe-list__content_button {
        width: 100%;
        padding: 9px 0 11px;
        height: 40px;
        margin-top: 15px;
        margin-left: 0;
    }

    .subscribe-list__content {
        padding: 20px 20px 35px;
    }

    .subscribe-list__header {
        position: relative;
        margin-bottom: 0;
        padding: 22px 20px 24px;
    }

    .subscribe-list__header-open-content-mobile {
        border-bottom: 1px solid #e4e4e4;
    }

    .subscribe-list__header_text-dekstop {
        display: none;
    }

    .subscribe-list__header_text-mobile {
        display: inline-block;
        vertical-align: middle;
    }

    .subscribe-list__header_arrow-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 15.8px;
    }

    .subscribe-list__success,
    .subscribe-list__error {
        margin-top: 0;
    }

    .subscribe-list__wrap-categories-wrap_checkbox-container-wrap {
        width: 100%;
    }
    .subscribe-list__wrap-categories-wrap {
        margin-bottom: 13px;
    }
    .tooltip-email {
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    .subscribe-list {
        width: calc(100% + 40px);
    }
}
