.wrapper {
  display: flex;
  align-items: center;
  color: var(--gray60_100);
  gap: var(--unit_1);
}

.wrapper > .icon {
  display: flex;
  color: var(--gray20_100);
}

.wrapper > .icon > * {
  color: var(--gray20_100);
}
