.wrapper {
  display: flex;
  justify-content: space-between;
  color: var(--gray60_100);
}

.wrapper._size_s {
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
}

.wrapper._size_m {
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
}

.wrapper > * {
  white-space: nowrap;
}

.wrapper._size_s > * + * {
  margin-left: var(--unit_3);
}

.wrapper._size_m > * + * {
  margin-left: var(--unit_5);
}

@media screen and (min-width: 1025px) {
  .wrapper._size_m > * + * {
    margin-left: var(--unit_6);
  }
}
