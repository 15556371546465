.image-wrapper {
  display: flex;
  text-decoration: none;
}

.image-wrapper > div {
  /* stylelint-disable-next-line declaration-no-important */
  border-top-left-radius: 3px !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-top-right-radius: 3px !important;
}

.content-wrapper {
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.price {
  margin-bottom: var(--unit_0);
  font-size: var(--fontSize_18px);
  font-weight: bold;
  line-height: var(--lineHeight_24px);
  color: var(--black_100);
}

.title {
  margin-bottom: var(--unit_1);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  color: var(--black_100);
}

.underground-wrapper {
  margin-bottom: var(--unit_1);
}

.price,
.title {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.address {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  max-height: calc(var(--lineHeight_22px) * 3);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  text-wrap: initial;
  color: var(--gray60_100);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.address._two-strings {
  max-height: calc(var(--lineHeight_22px) * 2);
  -webkit-line-clamp: 2;
}
