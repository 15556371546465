.wrapper {
  display: flex;
  align-items: center;
  gap: var(--unit_2);
}

.more-link > * {
  padding: var(--unit_1) 0;
  cursor: pointer;
  color: var(--text-main-default);
}
