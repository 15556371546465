.wrapper {
  padding: var(--unit_1) var(--unit_2);
  display: inline-block;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  white-space: nowrap;
  border-radius: 4px;
}

.wrapper.author {
  color: var(--light-text-primary);
  background-color: var(--light-background-secondary);
}

.wrapper.best-advice {
  color: var(--light-accent-primary-positive);
  background-color: var(--light-surface-positive);
}
