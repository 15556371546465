.wrapper {
  background-color: var(--superblack_100);
}

.iframe-wrapper {
  margin: 0 auto;
  display: flex;
}

.iframe-wrapper > iframe {
  margin: 0 auto;
}

.image-wrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.background-image {
  margin: 0 auto;
  padding: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 640px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.background-image::before {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 68px;
  height: 48px;
  transform: translate(-50%, -50%);
}
