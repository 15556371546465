.wrapper {
  margin-bottom: 6px;
  display: block;
}

.link {
  margin-right: -26px;
  margin-left: calc(var(--unit_2) * -1);
  padding: 0 var(--unit_2);
  display: flex;
  align-items: center;
  height: var(--unit_6);
  font-size: var(--fontSize_14px);
  font-weight: bold;
  line-height: var(--lineHeight_20px);
  text-decoration: none;
  color: var(--black_100);
  border: 1px solid transparent;
}

.link._active,
.link._hover {
  color: var(--primary_100);
}

.link._hover {
  background: var(--gray10_100);
  border: 1px solid var(--gray10_100);
}

.icon-before-wrapper {
  margin-right: var(--unit_2);
  line-height: 0;
}

.chevron {
  margin-right: 17px;
  margin-left: auto;
  line-height: 0;
}

.chevron > svg {
  color: var(--primary_100);
}

.label-wrapper {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
