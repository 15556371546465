.wrapper {
  display: flex;
  align-items: center;
  gap: var(--unit_5);
}

.likes-wrapper {
  display: flex;
  align-items: center;
  gap: var(--unit_3);
}

.like-wrapper > * > * {
  color: var(--light-text-seconary);
}

.like-wrapper._clickable:hover * {
  cursor: pointer;
  color: var(--primary_100);
}

.like-wrapper._liked * {
  color: var(--primary_100);
}

.like-wrapper._disliked * {
  color: var(--text-negative-default);
}
