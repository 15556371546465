.headnote {
  margin-bottom: var(--unit_4);
  display: flex;
  align-items: center;
  gap: var(--unit_1);
}

.headnote > *:first-child {
  width: 100%;
}

.headnote > *:last-child {
  margin-left: auto;
}

.comment {
  padding: var(--unit_4);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
  word-break: break-word;
  overflow-wrap: break-word;
  color: var(--light-text-primary);
  background: var(--gray6_100);
  border-radius: 0 var(--unit_3) var(--unit_3) var(--unit_3);
}

.footnote {
  margin-top: var(--unit_2);
  display: flex;
  align-items: center;
  gap: var(--unit_4);
  transition: margin-top 100ms ease-in-out;
}

.footnote._empty {
  margin-top: 0;
}

.controls-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--unit_2);
}

.form-wrapper {
  padding-top: var(--unit_4);
}

.comments {
  margin-top: var(--unit_5);
}

@media screen and (min-width: 641px) {
  .wrapper {
    margin-left: 56px;
  }

  .headnote {
    margin-left: -56px;
  }

  .footnote {
    gap: var(--unit_7);
  }

  .controls-wrapper {
    margin-left: 0;
    gap: var(--unit_2);
  }

  .form-wrapper {
    padding-top: var(--unit_3);
  }

  .comments {
    margin-top: var(--unit_6);
  }
}
