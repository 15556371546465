.tabs {
  z-index: 0;
  margin-bottom: 24px;
  padding-left: 1px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow: hidden;
  height: 28px;
}

.tabs button {
  margin-right: 8px;
  flex: 0 0 auto;
  height: 28px;
}

@media (max-width: 640px) {
  .tabs {
    height: auto;
  }

  .tabs button {
    height: auto;
  }
}
