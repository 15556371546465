.container {
  columns: 4;
  column-gap: 30px;
}

@media (max-width: 1024px) {
  .container {
    columns: 2;
    column-gap: 30px;
  }
}

@media (max-width: 640px) {
  .container {
    columns: 1;
  }
}
