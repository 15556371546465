.search-container {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.95);
}

.search {
  position: relative;
  margin: 0 auto;
  padding: 34px 40px 0;
  float: none;
}
.search-form {
  position: relative;
  height: 44px;
  border-bottom: 2px solid #182930;
  border-radius: 0.5px;
}
.search-form [type='text'] {
  padding-top: 0;
  padding-left: 36px;
  padding-bottom: 0;
  width: 100%;
  height: 28px;
  font-family: Lato, Arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
  text-align: left;
  color: #121212;
  background: transparent;
  border: 0;
  outline: none;
}
.search-form [type='text']::placeholder {
  font-family: Lato, Arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
  color: #999;
}
.search-form span {
  position: relative;
  display: block;
}
.search-form__reset {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 22px;
  height: 22px;
  cursor: pointer;
  background: url('img/icn-close-desktop.svg') center no-repeat;
  transition: opacity 0.15s;
}
.search-form__reset:hover {
  opacity: 0.75;
}
.search-form__reset:active {
  opacity: 0.2;
}
.search-form__submit {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('img/icn-search-desktop.svg') center no-repeat;
  background-size: 24px;
  border: 0;
  outline: none;
  transition: opacity 0.15s;
}
.search-form__submit:hover {
  opacity: 0.75;
}
.search-form__submit:active {
  opacity: 0.2;
}
input.search-form__input {
  padding: 0;
  height: 28px;
  background-color: transparent;
  outline: none;
}
input.search-form__input:active {
  outline: none;
}
.search-form__no-result {
  padding: 34px 0 0 38px;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  color: #7a7a7a;
  background: url('img/bad-smile.svg') bottom left no-repeat;
}
.search-preloader {
  margin-top: 30px;
  text-align: center;
}

.result-global__item--hash,
.result-global__item--category {
  padding: 21px 20px 20px;
  display: block;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;
  color: #7a7a7a;
  border-bottom: 1px solid #e4e4e4;
  border-radius: 1px;
}
.result-global__item--hash:hover,
.result-global__item--category:hover {
  background-color: rgba(43, 135, 219, 0.05);
}
.result-global__item--hash:hover h4,
.result-global__item--category:hover h4 {
  color: #e84260;
}

.result-global__item--hash h4 {
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #2b87db;
}

.result-global__item--category__type {
  margin-right: 4px;
  font-size: 10px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e84260;
}
.result-global__item--category h4 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #121212;
}

.result-post {
  padding: 0 10px;
}
.result-post_main-heading {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #121212;
}
.result-post-main-nav {
  position: relative;
  padding-top: 17px;
  padding-bottom: 13px;
  border-bottom: 2px solid #182930;
  border-radius: 0.5px;
}
.result-post-main-nav__a-side,
.result-post-main-nav__button-bar {
  display: inline-block;
}
.result-post-main-nav__button-bar {
  float: right;
}
.result-post-main-nav::after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}
.result-post-main-nav_heading {
  margin: 0;
  padding-top: 14px;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  text-align: left;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #121212;
}
.result-post-main-nav_button {
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  vertical-align: middle;
  color: #7a7a7a;
  background: transparent;
  border: 0;
}

[class*='result-post-main-nav_button'][class*='active'] {
  color: #121212;
}
.result-post-main-nav_material,
.result-post-main-nav_material-label {
  font-family: LucidaGrande, Lato, Arial;
  color: #7a7a7a;
}
.result-post-main-nav_material {
  margin-left: 12px;
}
.result-post-main-nav_button:not(:last-child) {
  margin-right: 16px;
}
.result-post-main-nav_button.active,
.result-post-main-nav_button:active,
.result-post-main-nav_button:hover {
  color: #121212;
}
.result-post-main-nav_search {
  margin-right: 20px;
  display: none;
  float: right;
  cursor: pointer;
}

.result-post-article {
  padding-bottom: 24px;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
}
/* TO DO: add active state JS for articles with image (add class 'has-img'), */
/* TO DO: add <img> into block with class 'result-post-article-image' after <article> open tag */

[class^='result-post-article'][class*='has-img'] {
  position: relative;
  padding-right: 200px;
}
.result-post-article-image {
  position: absolute;
  top: 10px;
  right: 0;
  width: 186px;
  height: 139px;
}
.result-post-article-image img {
  width: 100%;
}

.result-post-article-nav {
  margin-top: 13px;
  padding: 6px 0;
}
.result-post-article-nav button,
.result-post-article-nav span {
  padding: 0;
  font-size: 10px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7a7a7a;
  background: transparent;
  border: 0;
}
.result-post-article-nav .result-post-article-nav_decline {
  padding: 5px;
  display: none;
  text-transform: uppercase;
  color: white;
  background-color: #ff5050;
  border: 0;
  border-radius: 3px;
}
.result-post-article-nav .result-post-article-nav_accepted {
  padding: 5px;
  display: none;
  text-transform: uppercase;
  color: white;
  background-color: #7fbe70;
  border: 0;
  border-radius: 3px;
}
.result-post-article-nav button:first-child {
  margin-right: 8px;
  color: #e84260;
}
.result-post-article-nav button:last-of-type {
  margin-right: 8px;
}
.result-post-article-nav span:first-of-type {
  margin-left: 0;
}
.result-post-article_heading {
  margin: 0;
  font-size: 22px;
  line-height: 28px;
}
.result-post-article_text {
  margin-top: 11px;
  margin-bottom: 7px;
}

.result-post-article-author_avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}
.result-post-article-author_avatar-outer {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  max-height: 0;
}
.result-post-article-author_avatar-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.result-post-article-author_avatar-img {
  height: 100%;
  background-image: url('img/no-image.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.result-post-article-author_info {
  padding-top: 3px;
  display: inline-block;
  min-height: 40px;
  font-size: 12px;
  vertical-align: middle;
}
.result-post-article-author_info-name {
  margin: 0;
}
.result-post-article-author_info-descr {
  color: #7a7a7a;
}
.result-post-article-author_rating {
  padding-top: 3px;
  display: inline-block;
  min-height: 40px;
  vertical-align: middle;
}
.result-post-article-author_rating > svg:first-child {
  margin: 0 8px;
}
.result-post-article-footer {
  font-family: LucidaGrande, Lato;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #7a7a7a;
}
.result-post-article-footer_watched,
.result-post-article-footer_comments {
  margin-left: 13px;
  padding-left: 20px;
  display: inline-block;
  font-family: Lato, Arial, sans-serif;
  line-height: 16px;
  vertical-align: baseline;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left 0;
  background-size: contain;
}
.result-post-article-footer_watched {
  background-image: url('img/icn-viewed.svg');
}
.result-post-article-footer_comments {
  margin-left: 14px;
  background-image: url('img/icn-comments.svg');
}
.result-post-article-footer_watched:hover,
.result-post-article-footer_comments:hover {
  color: black;
  background-image: url('img/like-hover.svg');
}
.result-post-article-footer_watched:hover {
  background-image: url('img/icn-viewed-hover.svg');
}
.result-post-article-footer_comments:hover {
  background-image: url('img/icn-comments-hover.svg');
}
.result-post-article-footer_name {
  margin-right: 8px;
  padding-right: 8px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  color: #7a7a7a;
  border-right: 1px solid #7a7a7a;
}
.result-paginator {
  padding-top: 40px;
  padding-bottom: 25px;
}
@media (max-width: 1024px) {
  .search {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .search-form {
    padding: 13px 40px 13px 20px;
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #7a7a7a;
  }
  .search-form [type='text'] {
    padding-top: 0;
    padding-left: 28px;
    padding-bottom: 0;
    height: 24px;
    font-size: 18px;
    line-height: 1.33;
  }
  .search-form [type='text']::placeholder {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
  }
  .search-form__submit {
    top: 0;
    left: 20px;
    bottom: 0;
    margin: auto;
    padding: 0;
    width: 16px;
    height: 16px;
    background-size: 16px;
  }
  .search-form__reset {
    top: 0;
    right: 20px;
    bottom: 0;
    margin: auto;
    width: 16px;
    height: 16px;
  }
  .search-form__no-result {
    padding: 12px 0 0 47px;
    font-size: 14px;
    line-height: 1.57;
    background-position-x: 21px;
    background-position-y: 15px;
    background-size: 16px;
  }
  .result-global__item--hash,
  .result-global__item--category {
    padding: 18px 0 20px;
  }
  .result-global__item--category {
    padding-top: 16px;
  }
  .result-global__item--category h4 {
    margin-bottom: 6px;
  }
  .result-post-main-nav_heading {
    padding-top: 13px;
    font-size: 18px;
    line-height: 24px;
  }
  .result-post-main-nav {
    padding: 17px 0;
  }
  .result-post-main-nav__a-side,
  .result-post-main-nav__button-bar {
    display: block;
  }
  .result-post-main-nav__button-bar {
    margin-top: 19px;
    float: none;
  }
  .result-post-main-nav_button:not(:last-child) {
    margin-right: 20px;
  }
  .result-post-article-nav {
    margin-top: 11px;
    padding: 5px 0;
  }
  .result-post-article_heading {
    font-size: 18px;
    line-height: 24px;
  }
  .result-post-article_text {
    margin-top: 8px;
    margin-bottom: 10px;
  }
  [class^='result-post-article'][class*='has-img'] {
    padding-top: 24px;
    padding-right: 0;
  }
  .result-post-article-image {
    position: static;
    overflow: hidden;
    width: initial;
    height: initial;
    border-radius: 1px;
  }

  .result-global {
    padding: 0 21px 0 19px;
  }

  span.search-form__input-container {
    padding: 0;
  }
}
