.post-link,
.comment-text {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.post-link {
  margin-bottom: var(--unit_3);
  overflow: hidden;
  line-height: var(--lineHeight_22px);
  text-decoration: none;
  color: unset;
  transition: color 200ms ease-in-out;
}

.post-link:hover {
  color: var(--primary_100);
}

.comment-link {
  margin-bottom: var(--unit_2);
  padding: 10px var(--unit_3) var(--unit_3);
  display: flex;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  text-decoration: none;
  color: var(--black_100);
  background-color: var(--gray6_100);
  border-radius: 12px 12px 12px 0;
  transition: background-color 200ms ease-in-out;
}

.comment-link:hover {
  background-color: var(--gray10_100);
}

.footnote {
  display: flex;
  justify-content: space-between;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  text-decoration: none;
  cursor: pointer;
  color: var(--gray60_100);
}

.footnote > *:first-child {
  margin-right: auto;
  white-space: nowrap;
}

.footnote > * + * {
  margin-left: var(--unit_3);
  white-space: nowrap;
}

.avatars,
.skeleton-avatars {
  display: flex;
}

.avatars > * {
  border: 1.5px solid var(--light-surface-neutral);
}

.skeleton-avatars > * {
  border: 1.5px solid var(--white_100);
}

.avatars > *:nth-child(1),
.skeleton-avatars > *:nth-child(1) {
  z-index: 3;
}

.avatars > *:nth-child(2),
.skeleton-avatars > *:nth-child(2) {
  z-index: 2;
}

.avatars > *:nth-child(3),
.skeleton-avatars > *:nth-child(3) {
  z-index: 1;
}

.avatars > * + *,
.skeleton-avatars > * + * {
  margin-left: calc(var(--unit_3) * -1);
}
