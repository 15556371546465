.container {
  padding: 16px 24px 28px;
  width: 100%;
  background: rgba(21, 34, 66, 0.06);
  position: relative;
  border-radius: 4px;
}

.logo {
  background: url(img/realtor.png) no-repeat center;
  background-size: 100%;
  width: 180px;
  height: 112px;
  margin: 0 auto;
}

.title {
  font-family: Lato, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #152242;
  margin: 20px 0 4px;
}

.description {
  font-family: Lato, Arial, sans-serif;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
}

.action .button {
  font-family: Lato, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: #0468FF;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
}

.action .button:hover {
  background-color: #0661ec;
}

.button-text {
  color: #FFFFFF;
}

@media (min-width: 641px) {
  .container.inlist {
    padding: 16px 234px 32px 20px;
  }

  .inlist .logo {
    position: absolute;
    right: 12px;
    bottom: 0;
    background: url(img/realtor-listing.png) no-repeat center;
    background-size: 100%;
    width: 227px;
    height: 141px;
  }

  .inlist .title {
    font-size: 22px;
    line-height: 28px;
    margin: 0;
  }

  .inlist .description {
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
  }
}

@media (max-width: 640px) {
  .container {
    padding: 16px 20px 28px;
    border-radius: 0;
  }

  .container.inlist {
    display: block;
    border-radius: 4px;
  }

  .action .button {
    font-size: 16px;
    line-height: 22px;
    padding: 9px 16px;
  }

  .title {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }

  .description {
    text-align: center;
  }

  .action {
    display: flex;
    justify-content: center;
  }
}
