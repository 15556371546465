.wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
  list-style: none;
  background: var(--black_100);
}

.wrapper + .wrapper {
  padding-top: 27px;
}

.wrapper + .wrapper::before {
  position: absolute;
  top: 13px;
  right: var(--unit_4);
  left: var(--unit_4);
  height: 1px;
  content: '';
  background-color: var(--gray40_100);
}
