.wrapper > * > * {
  margin-bottom: var(--unit_5);
}

.wrapper > *:last-child > * {
  margin-bottom: 0;
}

@media screen and (min-width: 641px) {
  .wrapper > * > * {
    margin-bottom: var(--unit_6);
  }
}
