.sub-dialog-content-header {
  display: block;
  margin-bottom: 8px;
  margin-top: 0;

  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-align: left;
  color: #121212;
}

.sub-dialog-content-text {
  display: block;

  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: #121212;
}

.sub-dialog-content-controls {
  display: block;
  margin-top: 11px;
}

.c-digest-form-container {
  display: flex;
  flex-flow: row wrap;
}

.c-digest-form-email {
  width: 240px;
  flex-grow: 1;
  display: inline-block;
}

.c-digest-form-license {
    margin-top: 8px;
    font-family: Lato, Arial, sans-serif;
    font-size: 12px;
    min-width: 100%;
    line-height: 16px;
    text-align: left;
    color: #7a7a7a;
}

.c-digest-form-license-link {
    color: #2b87db;
    text-decoration: none;
}

.c-digest-success-header {
  font-size: 14px;
  line-height: 1.29;
  font-weight: bold;
  color: #121212;

  display: block;
}

.c-digest-success-text {
  font-size: 14px;
  line-height: 18px;
  color: #121212;

  display: block;
  margin-left: 20px;
}

.c-digest-success-header:before {
  content: '';
  background-image: url('img/subscribe-done-icon.svg');
  display: inline-block;
  width: 14px;
  height: 10px;

  margin-right: 6px;
}

.c-digest-fail-text {
  font-size: 14px;
  line-height: 1.29;
  color: #121212;

  display: block;
}

.c-digest-fail-text:before {
  content: '';
  background-image: url('img/subscribe-err-icon.svg');
  display: inline-block;
  width: 12px;
  height: 12px;

  margin-right: 8px;
}

.c-digest-email {
  color: #df294e;
  margin-left: 5px;
}

.mobile-content {
  display: none;
}

.c-digest-form-email input {
    font-family: Lato, Arial, sans-serif;
}

@media screen and (max-width: 540px) {
  .desktop-content {
    display: none;
  }

  .mobile-content {
    display: block;
  }

  .sub-dialog-content-text {
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.43;
  }

  .c-digest-form-email {
    width: 100%;
  }

  .c-digest-form-email input {
    height: 40px;
  }

  .c-digest-fail-text, .c-digest-success-header {
    font-size: 18px;
  }

  .sub-dialog-fail-text {
    margin-left: 20px;
  }

  .c-digest-success-header {
    margin-bottom: 8px;
  }

  .sub-dialog-content-header {
    text-align: center;
    font-size: 18px;
    line-height: 1.33;
  }

  .sub-dialog-content-controls,
  .c-digest-form {
    margin-top: 0;
  }

  .c-digest-form {
    margin-bottom: 0;
  }

  .break-mobile-hidden {
    display: none;
  }

  .c-digest-form-container {
      flex-flow: column nowrap;
  }

  .c-digest-form-license {
      text-align: center;
      order: 3;
  }

  .c-digest-form-license-link {
      display: block;
  }
}
