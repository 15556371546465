.wrapper {
  display: block;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid var(--gray10_100);
  transition: border-bottom-color 100ms ease-in-out;
}

.inner-wrapper {
  margin: 0 calc(var(--unit_4) * -1);
  padding: var(--unit_4);
  transition: background-color 100ms ease-in-out;
}

.rubrics {
  margin-bottom: var(--unit_1);
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit_1);
}

.rubric {
  padding: var(--unit_0) var(--unit_2) 3px;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  text-transform: lowercase;
  color: var(--black_100);
  background: var(--superblack_6);
  border-radius: 4px;
}

.title {
  margin-bottom: var(--unit_1);
}

.tag .title > * {
  color: var(--primary_100);
}

.description > * {
  white-space: nowrap;
  color: var(--gray60_100);
}

@media screen and (min-width: 641px) {
  .wrapper:hover {
    margin-top: -1px;
    border-top: 1px solid white;
    border-bottom-color: transparent;
  }

  .wrapper:hover .inner-wrapper {
    background-color: var(--secondary_button_10);
    border-radius: 4px;
  }
}
