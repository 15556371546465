.add-question__wrapper {
  background-color: #f4f4f4;
}

@media (max-width: 1024px) {
  .breadcrumbs-wrap {
    padding: 20px 0 0 20px;
  }

  .breadcrumbs-wrap-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .breadcrumbs-wrap {
    padding: 0;
  }

  .breadcrumbs-wrap-mobile {
    padding: 0 0 13px 20px;
    display: block;
  }
}
