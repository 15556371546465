.list-content {
  margin: 0 0 40px;
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.list-content__banner {
  padding: 20px 0;
  background: white;
}

@media screen and (max-width: 540px) {
  body {
    background: white;
  }

  .list-content__item {
    width: auto;
  }
}

@media screen and (max-width: 640px) {
  .list-content__banner {
    margin-top: -1px;
  }
}
