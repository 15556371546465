.wrapper {
  padding: var(--unit_2) 0;
  display: flex;
  align-items: center;
}

.link,
.text {
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_22px);
}

.text {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--black_100);
}

.link {
  text-decoration: none;
  color: var(--gray40_100);
}

.link:hover {
  text-decoration: underline;
}

.delimiter {
  margin: 0 var(--unit_1);
  line-height: 0;
}

.delimiter > svg {
  color: var(--gray40_100);
}
