.description {
  font-family: Lato, Arial, sans-serif;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 20px;
  padding-right: 16px;
}

.description__title {
  font-weight: bold;
}

.popup {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 20px 16px;
  width: 372px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  animation: popup-appear ease 0.5s;
}

.divider {
  width: 1px;
  background-color: #FFFFFF;
  transform: scaleX(0.5);
  -ms-transform: scaleX(1);
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.action {
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.button {
  font-family: Lato, Arial, sans-serif;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 8px;
  margin: -8px;
}

@media (max-width: 640px) {
  .popup {
    right: 12px;
    width: calc(100% - 24px);
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@keyframes popup-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
