.wrapper {
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  background-size: cover;
  border-radius: var(--unit_2);
}

.content-wrapper {
  padding: var(--unit_4);
  display: flex;
  height: 100%;
  align-items: center;
  color: var(--black_100);
}

.subscribed-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.subscribed-wrapper > svg {
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
}

.subscribed-wrapper > * + * {
  margin-top: var(--unit_2);
}

.subscribed-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}

.subscribed-content > * {
  flex: 0 0 auto;
}

.subscribed-content > *:first-child {
  margin-right: var(--unit_1);
}

.unsubscribed-wrapper {
  width: 100%;
}

.unsubscribed-wrapper > *:nth-child(1) {
  margin-bottom: var(--unit_4);
}

.fields-wrapper {
  margin-bottom: var(--unit_4);
}

.input-wrapper {
  margin-bottom: var(--unit_2);
}

.error-wrapper {
  overflow: hidden;
  max-height: 0;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--error_100);
  transition: max-height 300ms ease;
}

.error-wrapper._active {
  max-height: 32px;
}

.error {
  margin-top: 3px;
}

.categories-wrapper {
  padding-bottom: var(--unit_2);
}

.options-content > *:first-child {
  margin-bottom: var(--unit_4);
}

.options-trigger {
  margin-bottom: var(--unit_4);
  padding: 10px 0 var(--unit_3) 0;
  display: inline-flex;
  align-items: center;
  gap: var(--unit_2);
  flex-wrap: nowrap;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  cursor: pointer;
  color: var(--primary_100);
}

.options-trigger > svg {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.description {
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
}

.description a {
  text-decoration: none;
  color: var(--primary_100);
}
