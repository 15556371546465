.wrapper {
  display: flex;
  justify-content: center;
}

.button-wrapper + .button-wrapper {
  margin-left: var(--unit_2);
}

.button-wrapper > a {
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
}

.button-wrapper._active > a,
.button-wrapper._active > a:active {
  cursor: auto;
  background: var(--black_10);
  border: 1px solid var(--black_100);
}

.button-wrapper._disabled > a,
.button-wrapper._disabled > a:active,
.button-wrapper._disabled > a:hover,
.button-wrapper._disabled > a:focus {
  cursor: auto;
  color: var(--gray20_100);
  background: transparent;
  box-shadow: none;
}

.button-wrapper._disabled > a > svg {
  color: var(--gray20_100);
}

.button-wrapper > a > span {
  font-weight: normal;
}

.wrapper > .link + .link {
  margin-left: var(--unit_4);
}
