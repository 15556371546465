.wrapper {
  padding: var(--unit_0) var(--unit_2) 3px var(--unit_2);
  display: flex;
  height: 24px;
  align-items: center;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--black_100);
  background: var(--gray6_100);
  border-radius: var(--unit_1);
}

.wrapper > svg {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.icon-wrapper {
  margin-top: var(--unit_0);
  margin-right: calc(var(--unit_1) * -1);
  margin-left: var(--unit_1);
  display: flex;
  cursor: help;
}

.wrapper._published {
  color: var(--success_100);
  background: var(--success_10);
}

.wrapper._moderation {
  color: var(--warning_100);
  background: #fff2e6;
}

.wrapper._draft {
  color: var(--primary_100);
  background: var(--primary_10);
}

.wrapper._rejected {
  color: var(--error_100);
  background: var(--error_10);
}
