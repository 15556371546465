.social-network {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.social-network__comment-btn {
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
    line-height: 1.43;
    background-color: #2b87db;
    border-radius: 42px;
    border: 0;
    padding: 3px 16px 5px;
    margin-right: 24px;
    cursor: pointer;
    outline: none;
    font-family: Lato, Arial, sans-serif;
    vertical-align: middle;
    font-weight: bold;
    margin-bottom: 24px;
}

.social-network__comment-btn:hover,
.social-network__comment-btn:focus {
    background-color: #256ba5;
}

.social-network__comment-canot {
    margin-top: 24px;
    padding: 3px 16px 5px;
    opacity: 0.9;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #7a7a7a;
    border-radius: 2px;
    background-color: #e4e4e4;
}

.social-network__icons {
    margin-top: 24px;
    display: inline-block;
}

.social-network__icons_aligned {
    margin-top: 24px;
    display: inline-block;
    margin-left: auto;
}

.social-network__icons_fb,
.social-network__icons_tg,
.social-network__icons_vk,
.social-network__icons_ok,
.social-network__icons_tw {
    width: 32px;
    height: 32px;
    margin-left: 8px;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
}

.social-network__icons_fb {
    background: url(img/share-fb.svg);
}

.social-network__icons_fb:hover {
    background: url(img/share-fb-hover.svg);
}
.social-network__icons_fb:active {
    background: url(img/share-fb-hover.svg);
    transform: scale(0.98);
}
.social-network__icons_tg {
    background: url(./img/share-tg.svg);
}

.social-network__icons_tg:hover {
    background: url(./img/share-tg-hover.svg);
}
.social-network__icons_tg:active {
    background: url(./img/share-tg-hover.svg);
    transform: scale(0.98);
}
.social-network__icons_vk {
    background: url(img/share-vk.svg);
}

.social-network__icons_vk:hover {
    background: url(img/share-vk-hover.svg);
}
.social-network__icons_vk:active {
    background: url(img/share-vk-hover.svg);
    transform: scale(0.98);
}
.social-network__icons_ok {
    background: url(img/share-ok.svg);
}

.social-network__icons_ok:hover {
    background: url(img/share-ok-hover.svg);
}
.social-network__icons_ok:active {
    background: url(img/share-ok-hover.svg);
    transform: scale(0.98);
}
.social-network__icons_tw {
    background: url(img/share-tw.svg);
}

.social-network__icons_tw:hover {
    background: url(img/share-tw-hover.svg);
}
.social-network__icons_tw:active {
    background: url(img/share-tw-hover.svg);
    transform: scale(0.98);
}

.social-network__icons_fb:focus,
.social-network__icons_tg:focus,
.social-network__icons_vk:focus,
.social-network__icons_ok:focus,
.social-network__icons_tw:focus  {
    transform: scale(.98);
}

@media screen and (max-width: 639px) {
    .social-network {
        padding: 0;
        flex-wrap: wrap;
    }

    .social-network__comment-btn {
        display: block;
        padding: 9px 0 11px;
        margin-right: 0;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .social-network__comment-canot {
        margin-top: 20px;
        padding: 9px 0 11px;
        width: 100%;
        text-align: center;
    }

    .social-network__icons {
        float: none;
        display: block;
        margin-top: 28px;
        margin-left: 0;
    }

    .social-network__icons_aligned {
        float: none;
        display: block;
        margin-top: 28px;
        margin-left: 0;
    }

    .social-network__icons_tg {
        margin-left: 0;
    }

    .social-network__icons_fb,
    .social-network__icons_tg,
    .social-network__icons_vk,
    .social-network__icons_ok,
    .social-network__icons_tw {
        vertical-align: bottom;
        margin-left: 16px;
    }

    .social-network__icons_tg {
        margin-left: 0;
    }
}
