.wrapper {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.wrapper._loading {
  opacity: 0.6;
}

.items-wrapper {
  padding-top: var(--unit_4);
}

.items-wrapper > * + * {
  margin-top: var(--unit_4);
  padding-top: var(--unit_4);
  border-top: 1px solid var(--gray10_100);
}

.more-link {
  margin-top: var(--unit_4);
  padding: var(--unit_1) 0;
  display: inline-block;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  text-decoration: none;
}

.more-link * {
  text-decoration: none;
  color: var(--primary_100);
}

.more-link:hover *,
.more-link:active * {
  color: var(--fill_primary_hover_100);
}
