.bread-crumbs-wrapper {
  margin: var(--unit_4) 0 var(--unit_4);
}

.cards-wrapper {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}

.cards-wrapper > * {
  margin-bottom: var(--unit_4);
  padding-right: 10px;
  padding-left: 10px;
}

.list-wrapper > .list-item-wrapper {
  padding: var(--unit_8) 0;
}

.list-wrapper > .list-item-wrapper + .list-item-wrapper {
  border-top: 1px solid var(--gray20_100);
}

.list-wrapper > .list-item-wrapper:last-child {
  border-bottom: 1px solid var(--gray20_100);
}

.controls-wrapper > * {
  margin: var(--unit_6) 0 var(--unit_10);
}

.insert-wrapper {
  margin: 0;
  width: 100%;
}

.insert-wrapper._active {
  margin-bottom: var(--unit_4);
}

@media screen and (min-width: 641px) {
  .cards-wrapper > * + .insert-wrapper._active {
    margin-top: var(--unit_6);
  }

  .cards-wrapper > .insert-wrapper._active {
    margin-bottom: var(--unit_10);
  }

  .insert-wrapper._active {
    margin: var(--unit_6) 0;
  }
}
