.error-caption {
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: 210px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
  text-align: center;
  color: #121212;
}

.refresh-button {
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

@media (max-width: 540px) {
  .error-caption {
    font-size: 18px;
    line-height: 1.33;
  }

  .refresh-button {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    border-radius: 42px;
  }
}
