.advantage {
  font-family: Lato, Arial, sans-serif;
  padding-left: 24px;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #152242;
  margin-bottom: 12px;
}

.advantage:last-child {
  margin-bottom: 8px;
}

.advantage::before {
  content: '';
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  background: url(img/sucess.svg);
  position: absolute;
}

.advantage__content {
  padding: 0 24px;
}
