.title {
  margin-bottom: var(--unit_4);
  color: var(--black_100);
}

.scrolling-wrapper {
  margin-right: calc(var(--unit_4) * -1);
}

.scrolling-inner-wrapper {
  margin: 0 calc(var(--unit_1) * -1);
}

.slide-wrapper {
  padding-right: var(--unit_1);
  padding-left: var(--unit_1);
  display: inline-flex;
}

@media screen and (min-width: 641px) {
  .title {
    margin-bottom: var(--unit_10);
  }

  .scrolling-inner-wrapper {
    margin: 0 -10px;
  }

  .slide-wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }
}
