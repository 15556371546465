.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  background-color: var(--white_100);
  border: 1px solid #c9d1e5;
  border-radius: var(--unit_2, 8px);

  &--mobile {
    .photo-block {
      height: 148px;
    }
  }

  &--image-bottom {
    .content {
      border-top: none;
      border-bottom: 1px solid #c9d1e5;
    }

    .photo-block {
      order: 1;
    }

    .photo-label {
      top: unset;
      right: var(--unit_4, 16px);
      left: unset;
      bottom: var(--unit_4, 16px);
    }
  }

  &--mp-article {
    .photo-block {
      height: 100%;
    }
  }
}

.photo-block {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 292px;
  background: var(--white_100) no-repeat center;
  background-size: cover;
}

.photo-label,
.annotation {
  position: absolute;
  z-index: 1;
}

.photo-label {
  top: var(--unit_4, 16px);
  left: var(--unit_4, 16px);
}

.annotation {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.content {
  padding: var(--unit_4, 16px);
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--unit_2, 8px);
  justify-content: space-between;
  border-top: 1px solid #c9d1e5;
  flex: 1 0 auto;
}

.title-container {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_0, 2px);
}

.logo-container {
  display: flex;
  width: fit-content;
  height: fit-content;
  border: 1px solid #c9d1e5;
  border-radius: var(--unit_1, 4px);
}

@media (max-width: 640px) {
  .container {
    &--mp-article {
      .photo-block {
        height: 148px;
      }
    }
  }
}
