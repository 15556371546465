/* stylelint-disable declaration-no-important */
.wrapper > button {
  padding-right: 0;
  padding-left: 0;
  border-color: transparent !important;
}

.wrapper > button:not(:disabled) {
  color: var(--primary_100) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
