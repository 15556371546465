@media screen and (max-width: 1024px) {
  .aside-wrap {
    padding: 20px calc(20px - 0.5rem) 0 0;
  }
}

@media screen and (max-width: 768px) {
  .aside-wrap {
    padding: 0;
  }

  .journal__bottom-line_mobile {
    height: 20px;
    background-color: #f4f4f4;
  }
}
