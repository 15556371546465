.wrapper img {
  margin-bottom: 52px;
  max-width: 386px;
}

.info {
  max-width: 386px;
}

.title {
  margin: 0 0 var(--unit_4);
  font-size: var(--fontSize_38px);
  font-weight: bold;
  line-height: var(--lineHeight_46px);
  color: var(--black_100);
}

.description {
  margin: 0 0 var(--unit_8);
  font-size: var(--fontSize_18px);
  line-height: var(--lineHeight_24px);
  color: var(--gray60_100);
}

.button-wrapper {
  margin: 0 0 var(--unit_9);
}

.error-code {
  margin: 0 0 var(--unit_8);
  font-size: var(--fontSize_22px);
  line-height: var(--lineHeight_28px);
  color: var(--black_100);
}
