.wrapper {
  display: flex;
  align-items: center;
  height: 28px;
  cursor: pointer;
}

.wrapper > svg {
  min-width: 16px;
  min-height: 16px;
}

.wrapper:hover > * {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--primary_100) !important;
}

.searching-query {
  padding-left: var(--unit_2);
  display: block;
  overflow: hidden;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--gray40_100);
  transition: color 200ms ease-in-out;
}

.searching-query._active {
  color: var(--black_100);
}

.submit-wrapper {
  margin-top: var(--unit_6);
  padding-bottom: var(--unit_12);
  text-align: center;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.nothing-found-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.nothing-found-wrapper > * {
  padding: 0;
}
