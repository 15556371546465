.button {
  padding: var(--unit_2) var(--unit_1);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
  color: var(--light-controls-primary-basic);
}

.button > svg {
  color: var(--light-controls-primary-basic);
}

.button._clickable {
  cursor: pointer;
}

.button._disabled,
.button._disabled > svg {
  color: var(--gray20_100);
}
