.wrapper {
  margin-bottom: var(--unit_10);
  display: flex;
  flex-direction: column;
}

.main-article-wrapper {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .main-article-wrapper {
    margin-bottom: var(--unit_4);
  }
}

@media screen and (min-width: 641px) {
  .wrapper {
    margin-bottom: var(--unit_12);
    flex-direction: row;
  }

  .main-article-wrapper {
    margin-right: var(--unit_5);
    min-width: 66.53%;
    max-width: 660px;
  }

  .main-news-wrapper {
    position: relative;
    width: 100%;
  }

  .main-news-inner-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .wrapper {
    margin-bottom: 70px;
  }

  .main-article-wrapper {
    min-width: 66%;
    max-width: 732px;
  }
}
