.wrapper {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.wrapper._loading {
  opacity: 0.6;
}

.heading-wrapper {
  display: flex;
  align-items: center;
  gap: var(--unit_2);
}

.items-wrapper {
  padding-top: var(--unit_4);
}

.items-wrapper > * + * {
  margin-top: var(--unit_4);
  padding-top: var(--unit_4);
  border-top: 1px solid var(--gray10_100);
}
