/* stylelint-disable declaration-no-important */
.wrapper {
  max-width: 408px;
}

.form-wrapper {
  margin-top: var(--unit_4);
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--unit_2);
}

@media screen and (min-width: 641px) {
  .form-wrapper {
    flex-flow: wrap;
    flex-direction: row;
    align-items: flex-start;
  }

  /* инпут */
  .form-wrapper > *:nth-child(1) {
    flex: 1 1 auto;
  }

  /* чекбокс */
  .form-wrapper > *:nth-child(2) {
    order: 1;
  }
}
