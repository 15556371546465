.comments {
  margin-top: 36px;
}

.comments__headline {
  margin-bottom: 20px;
  padding-bottom: 17px;
  font-weight: bold;
  line-height: 1.43;
  border-bottom: 2px solid #121212;
  border-radius: 0.5px;
}

.comments___headline_title {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.comments__headline_num {
  color: #676767;
}

.comments__subscribe {
  float: right;
  cursor: pointer;
}

.login__error {
  cursor: pointer;
}

.comments__subscribe:hover {
  color: #e84260;
}

.comments__subscribe:hover::before {
  margin-right: 4px;
  width: 16px;
  height: 16px;
  content: url('img/subscribe-hover.svg');
}

.comments__subscribe::before {
  margin-right: 4px;
  width: 16px;
  height: 16px;
  content: url('img/subscribe.svg');
}

.comments__add-comment {
  margin: 0 auto;
  padding: 24px 26px 32px;
  width: 100%;
  background-color: #f4f4f4;
  border-left: 2px solid #182930;
  border-radius: 2px;
}

.comments__add-comment_input {
  padding: 3px 8px 5px;
  appearance: none;
  width: 100%;
  height: 28px;
  font-family: Lato, Arial, sans-serif;
  line-height: 1.43;
  color: #999;
  background-color: white;
  border: solid 1px #c9c9c9;
  border-radius: 4px;
  outline: none;
}

.comments__list {
  margin: 20px 0 36px;
}

.comments__list_wrap-comment {
  padding: 5px 0;
  box-sizing: border-box;
}

.comments__item_photo,
.comments__item_info,
.comments__form_info,
.comments__form_photo,
.comments__form_photo-default {
  display: inline-block;
  max-width: calc(100% - 52px);
  vertical-align: middle;
}

.comments__item_photo,
.comments__form_photo,
.comments__form_photo-default {
  position: relative;
  margin-right: 12px;
  width: 40px;
  height: 40px;
  background: #f4f4f4 no-repeat center;
  background-size: inherit;
  border-radius: 20px;
}

.comments__form_photo-default {
  background: #e4e4e4 url('img/user-default.svg') no-repeat center;
}

.comments__item_user-online,
.comments__form_user-online {
  position: absolute;
  top: 3px;
  right: -4px;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  background-color: #2e9e00;
  border: 3px solid white;
  border-radius: 7.5px;
}

.comments__item_name,
.comments__item_date,
.comments__form_sender {
  display: block;
}

.comments__item_name,
.comments__form_sender {
  margin-bottom: 3px;
  font-weight: bold;
  line-height: 1.29;
}

.comments__form_sender {
  margin-bottom: 1px;
}

.comments__item_name-text {
  margin-right: 8px;
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  text-decoration: none;
  color: #121212;
}

a.comments__item_name-text:hover {
  color: #e84260;
}

.comments__item_author {
  margin-right: 8px;
  display: inline-block;
  width: 50px;
  height: 15px;
  vertical-align: middle;
  background: url('img/author.svg');
}

.comments__item_honest {
  position: relative;
  top: 3px;
  margin-right: 8px;
  display: inline-block;
  width: 13px;
  height: 18px;
  font-weight: normal;
  vertical-align: middle;
}

.comments__item_profi {
  margin-right: 8px;
  display: inline-block;
  width: 35px;
  height: 15px;
  vertical-align: middle;
  background: url('img/badge-professional.svg');
}

.comments__item_best-advice {
  margin-right: 8px;
  display: inline-block;
  width: 105px;
  height: 15px;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.4;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  background: #7fbe70;
  border-radius: 3px;
}

.comments__item_date,
.comments__form_addressed {
  font-size: 12px;
  line-height: 1.33;
  color: #7a7a7a;
}

.comments__form_addressed {
  display: flex;
  font-weight: bold;
  align-items: center;
}

.comments__form_icon-answer {
  margin-right: 6px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('img/arrow-answer.svg') no-repeat center;
}

.comments__item_text {
  margin: 12px 0 6px;
  overflow: hidden;
  line-height: 1.43;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.comments__item_text a {
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  border-bottom: 1px solid transparent;
}

.comments__item_text a:hover {
  color: #256ba5;
  border-bottom-color: #b6d3ed;
}

.comments__item_bottom-line {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #7a7a7a;
}

.comments__item_bottom-line .comments__item_bottom-line-item,
.answer__item_bottom-line span {
  margin-right: 12px;
  cursor: pointer;
}

.comments__item_bottom-line .comments__item_bottom-line-item:last-child,
.answer__item_bottom-line span:last-child {
  margin-right: 0;
}

.comments__item_wrap-info,
.comments__form_wrap-info {
  position: relative;
}

.comments__list-answer {
  margin: 20px auto 0;
  width: 100%;
  border-left: 2px solid #e4e4e4;
}

.comments__list-answer_item {
  margin: 14px 14px 11px 18px;
  padding: 5px 5px 5px 6px;
}

.comments__list-answer_item:first-child {
  padding-top: 0;
}

.comments__list-answer_item:first-child.comments__list_item-new {
  padding-top: 5px;
}

.comments__list_item-new {
  background-color: #f4f8fd;
}

.comments__list_wrap-comment.comments__list_item-new {
  margin-left: -5px;
  padding-left: 5px;
  width: calc(100% + 10px);
}

.comments__list-answer .comments__item_text {
  margin-bottom: 6px;
}

.comments__list-answer_add {
  margin: 13px 0 0 24px;
  padding-top: 11px;
  border-top: 1px solid #e4e4e4;
  border-radius: 0.5px;
}

.comments__list-answer_add-btn {
  padding: 0;
  font-family: Lato, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  cursor: pointer;
  color: #7a7a7a;
  background: white;
  border: 0;
  outline: 0;
}

.comments__list-answer_add-btn:hover {
  color: #121212;
}

.comments__list_item {
  margin-bottom: 29px;
}

.comments__form {
  padding: 25px 26px 40px;
  background-color: #f4f4f4;
  border-top: 1px solid #e4e4e4;
}

.comments__form input,
.comments__form button {
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  border-radius: 4px;
}

.comments__form_add-comment {
  margin: 15px 0 0;
  padding: 9px 8px;
  display: block;
  width: 100%;
  height: 100px;
  appearance: none;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  resize: none;
  color: #121212;
  border: solid 1px #c9c9c9;
  border-radius: 4px;
  outline: none;
}

.comments__form_email,
.comments__form_name {
  margin-top: 15px;
  padding: 3px 8px 5px;
  display: inline-block;
  width: 100%;
  appearance: none;
  color: #121212;
  background-color: white;
  border: solid 1px #c9c9c9;
  outline: none;
}

.comments__form_email {
  margin-right: 20px;
}

.comments__form_add-comment:focus,
.comments__form_email:focus,
.comments__form_name:focus {
  border: solid 1px #121212;
}

button.comments__form_publish-btn {
  margin-right: 20px;
  padding: 3px 16px 5px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  background-color: #2b87db;
  border: 0;
  border-radius: 42px;
  outline: none;
}

button.comments__form_publish-btn:hover {
  background-color: #256ba5;
}

button.comments__form_publish-btn:focus {
  transform: scale(0.98);
}

.comments__form_publish {
  margin-top: 24px;
}

.answer__comment-form {
  margin-left: -24px;
  width: calc(100% + 48px);
}

.comments__form_warning {
  position: relative;
  margin: 15px 0;
  padding: 12px 34px 14px 14px;
  line-height: 1.43;
  background-color: #e4e4e4;
  border-radius: 3px;
}

.comments__form_warning-close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: url('img/close-warning.svg') no-repeat center;
}

.comments__likes-wrap-dekstop {
  display: inline;
}

.comments__likes-wrap-mobile {
  display: none;
}

.comments__form_add-comment-wrap {
  position: relative;
}

.comments__form_email-wrap,
.comments__form_name-wrap {
  position: relative;
  display: inline-block;
  width: calc(50% - 10px);
}

.comments__form_email-wrap {
  margin-right: 20px;
}

.comments__form_add-comment-error,
.comments__form_email-error,
.comments__form_name-error {
  position: absolute;
  z-index: 1;
  top: 0;
  right: -11px;
  padding: 5px 12px 7px 9px;
  max-width: 290px;
  line-height: 1.43;
  color: white;
  background-color: #ff5050;
  border-radius: 4px;
  transform: translateX(100%);
}

.comments__form_email-error,
.comments__form_name-error {
  top: 14px;
}

.comments__form_add-comment-error::before,
.comments__form_email-error::before,
.comments__form_name-error::before {
  position: absolute;
  top: 10px;
  left: -12px;
  display: block;
  content: '';
  border: 6px solid transparent;
  border-right: 6px solid #ff5050;
}

.comments__item_complain {
  position: relative;
  margin-right: 12px;
  display: inline-block;
}

.comments__item_complain .comments__item_complain-btn {
  top: 0;
  margin-top: 0;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #7a7a7a;
}

.comments__item_complain .comments__item_complain-btn:hover {
  color: #7a7a7a;
}

.comments__item_complain .comments__item_complain-btn > span {
  vertical-align: baseline;
}

.comments__item_complain .comments__item_complain-popup {
  top: 21px;
}

@media screen and (max-width: 639px) {
  .comments__likes-wrap-dekstop {
    display: none;
  }

  .comments__likes-wrap-mobile {
    margin-bottom: 8px;
    display: inline-block;
  }

  .comments__item_bottom-line .comments__item_bottom-line-item {
    display: inline-block;
  }
}

.comments__form_add-comment-empty,
.comments__form_name-empty,
.comments__form_email-invalid {
  border: solid 1px #ff5050;
}

@media screen and (max-width: 540px) {
  .comments {
    margin-top: 40px;
  }
  .comments__list_wrap-comment {
    margin-top: 23px;
  }

  .comments__list-answer_item {
    margin: 14px 0 11px 13px;
  }

  .comments__list-answer_add {
    margin: 13px 0 0 13px;
  }

  .comments__add-comment {
    padding: 24px 20px 32px;
    width: 100%;
  }

  .comments__subscribe {
    margin-top: 12px;
    display: inline-block;
    float: none;
  }

  .subscribe-wrap {
    display: block;
  }

  .comments__form {
    padding: 25px 20px 40px;
  }

  .comments__form input,
  .comments__form button {
    padding: 9px 12px 11px;
    width: 100%;
  }

  .comments__form_email {
    margin: 0;
  }

  .comments__form_publish {
    margin-top: 0;
  }

  button.comments__form_publish-btn {
    padding-top: 9px;
    padding-bottom: 11px;
  }

  .subscribe {
    display: none;
  }

  .comments__add-comment_input {
    padding: 9px 12px 11px;
    height: 40px;
  }

  .comments__form_name {
    margin-top: 0;
  }

  .comments__form_add-comment {
    padding: 9px 12px;
  }

  .answer__comment-form {
    margin-left: -18px;
    width: calc(100% + 23px);
  }

  .comments__form_warning {
    padding: 12px 13.3px 14px 14px;
  }

  .comments__form_email-wrap,
  .comments__form_name-wrap {
    margin-right: 0;
    display: block;
    width: 100%;
  }

  .comments__form_add-comment-error,
  .comments__form_email-error,
  .comments__form_name-error {
    position: relative;
    top: 0;
    right: 0;
    padding: 2px 0 3px;
    width: 100%;
    max-width: none;
    min-height: 25px;
    text-align: center;
    border-radius: 3px;
    transform: translate(0);
  }

  .comments__form_add-comment-offset-mobile,
  .comments__form_email-offset-mobile {
    height: 20px;
  }

  .comments__form_name-offset-mobile {
    height: 17px;
  }

  .comments__form_add-comment-error::before,
  .comments__form_email-error::before,
  .comments__form_name-error::before {
    display: none;
  }

  .comments__form_warning-close {
    position: relative;
    top: 0;
    right: 0;
    float: right;
  }
}
