.wrapper {
  padding: var(--unit_0) var(--unit_2) var(--unit_1) var(--unit_2);
  display: inline-flex;
  height: 24px;
  align-items: center;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--black_100);
  background: var(--gray6_100);
  border-radius: var(--unit_1);
}

a.wrapper {
  text-decoration: none;
  cursor: pointer;
}

a.wrapper:hover,
a.wrapper:active {
  background: var(--gray10_100);
}
