.wrapper {
  padding: var(--unit_4) var(--unit_4) var(--unit_5) var(--unit_4);
  display: flex;
  flex-direction: column;
  background: var(--gray6_100);
  border-radius: var(--unit_2);
}

.controls {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  height: 0;
  transition: height 200ms ease-in-out;
}

.controls._shown {
  height: 100px;
}

.controls-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--unit_3) var(--unit_3);
}

/* Кнопка */
.controls-wrapper > *:nth-child(1) {
  margin-bottom: var(--unit_1);
  width: 100%;
}

/* Свитчер */
.controls-wrapper > *:nth-child(2) {
  margin-right: 1px;
  margin-bottom: 1px;
}

@media screen and (min-width: 641px) {
  .controls._shown {
    height: calc(40px + var(--unit_3));
  }

  .controls-wrapper {
    flex-wrap: nowrap;
  }

  /* Кнопка */
  .controls-wrapper > *:nth-child(1) {
    margin-right: auto;
    margin-bottom: 0;
    width: auto;
  }

  /* Свитчер */
  .controls-wrapper > *:nth-child(2) {
    margin-bottom: 0;
  }
}
