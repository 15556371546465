/* Аватарка */
/* stylelint-disable-next-line selector-max-compound-selectors */
.wrapper > * > *:first-child > * {
  border: none;
}

/* Имя и описание */
/* stylelint-disable-next-line selector-max-compound-selectors */
.wrapper._white > * > *:last-child > * > *,
.wrapper._white > * > *:last-child > * {
  color: var(--white_100);
}

/* Имя */
/* stylelint-disable-next-line selector-max-compound-selectors */
.wrapper > * > *:last-child > * > * {
  font-size: var(--fontSize_14px);
  font-weight: bold;
  line-height: var(--lineHeight_20px);
  letter-spacing: -0.2px;
  color: var(--black_100);
}

/* Описание */
/* stylelint-disable-next-line selector-max-compound-selectors */
.wrapper > * > *:last-child > * {
  overflow: visible;
  font-size: var(--fontSize_14px);
  font-weight: normal;
  line-height: var(--lineHeight_20px);
  white-space: normal;
  letter-spacing: -0.2px;
  text-overflow: unset;
  color: var(--black_100);
}

@media screen and (min-width: 641px) {
  /* Враппер аватарки */
  .wrapper > * > *:first-child {
    margin-right: var(--unit_4);
  }

  /* Имя */
  /* stylelint-disable-next-line selector-max-compound-selectors */
  .wrapper > * > *:last-child > * > * {
    font-size: var(--fontSize_16px);
    line-height: var(--lineHeight_24px);
  }
}
