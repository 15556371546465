.advice-card-header-editbar {
    margin-top: 25px;
    padding-top: 13px;
    padding-bottom: 17px;
    border-bottom: 2px solid #121212;
    border-radius: .5px;
    width: 100%;
    border-top: 1px solid #e4e4e4;
}
    .advice-card-header-editbar div {
        font-weight: bold;
        text-decoration: none;
        color: #121212;
        display: inline-block;
        cursor: pointer;
    }
    .advice-card-header-editbar div:hover {
        color: #e84260;
    }
    .advice-card-header-editbar div:not(:first-child) {
        margin-left: 16px;
    }
    .advice-card-header-editbar div svg {
        margin-right: 5px;
        vertical-align: middle;
    }
    .advice-card-header-editbar_hide span,
    .advice-card-header-editbar_edit span,
    .advice-card-header-editbar_publish span {
        vertical-align: middle;
    }
    .advice-card-header-editbar .advice-card-header-editbar_hide .hide-icon {
        width: 16px;
        height: 16px;
        background: url('img/hide.svg') no-repeat center;
        display: inline-block;
    }
    .advice-card-header-editbar_hide:hover .hide-icon {
        background: url('img/hide-hover.svg') no-repeat center;
    }
    .advice-card-header-editbar .advice-card-header-editbar_edit .edit-icon {
        width: 16px;
        height: 16px;
        background: url('img/edit.svg') no-repeat center;
        display: inline-block;
    }
    .advice-card-header-editbar_edit:hover .edit-icon {
        background: url('img/edit-hover.svg') no-repeat center;
    }
    .advice-card-header-editbar .advice-card-header-editbar_publish .publish-icon {
        width: 16px;
        height: 16px;
        background: url('img/share.svg') no-repeat center;
        display: inline-block;
    }
    .advice-card-header-editbar_publish:hover .publish-icon {
        background: url('img/share-hover.svg') no-repeat center;
    }


@media (max-width: 768px) {
    .advice-card-header-editbar > div {
        display: inline-block;
        margin-right: 17px;
    }
    .advice-card-header-editbar > div:not(:first-child) {
        margin-left: 0;
    }
    .advice-card-header-editbar > div:last-child {
        margin-right: 0;
    }
}
