.wrapper {
  display: flex;
  height: 44px;
  align-items: stretch;
  justify-content: space-between;
  gap: var(--unit_5);
  border-bottom: 1px solid var(--light-stroke-primary);
}

.tabs-wrapper {
  display: flex;
  justify-content: stretch;
  gap: var(--unit_5);
}

.tab {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.tab._active {
  cursor: auto;
  border-bottom-color: var(--primary_100);
}

.tabs-after {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 641px) {
  .tabs-wrapper {
    gap: var(--unit_6);
  }
}
