.wrapper {
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
  gap: var(--unit_4);
  background: var(--light-surface-neutral);
  border-radius: 8px;
}

.content-wrapper {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: var(--unit_4);
}
