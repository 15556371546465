.wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.control-wrapper.absolute {
  position: absolute;
  z-index: 1;
}

.control-wrapper.absolute.prev {
  left: 0;
  transform: translateX(-50%);
}

.control-wrapper.absolute.next {
  right: 0;
  transform: translateX(50%);
}

.control-wrapper.relative.prev {
  padding-right: var(--unit_4);
}

.control-wrapper.relative.next {
  padding-left: var(--unit_4);
}

.scrolling-wrapper {
  width: 100%;
}
