.wrapper {
  padding: var(--unit_4);
  color: var(--black_100);
  background: var(--error_10);
  border-radius: var(--unit_2);
}

.wrapper h5 {
  margin: 0;
  font-size: var(--fontSize_10px);
  font-weight: bolder;
  line-height: var(--lineHeight_16px);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--superblack_60);
}
