.wrapper {
  display: block;
}

.wrapper > * + * {
  margin-top: var(--unit_4);
}

.list-wrapper {
  padding: 0;
  list-style: none;
}

.item-wrapper + .item-wrapper .link {
  border-top: 1px solid var(--gray20_100);
}

.link {
  padding: var(--unit_3) 0;
  display: block;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
  text-decoration: none;
  color: var(--black_100);
}

.link:hover {
  color: var(--primary_100);
}

@media screen and (min-width: 641px) {
  .link {
    padding: var(--unit_4) 0;
    font-size: var(--fontSize_18px);
  }
}
