.wrapper {
  position: relative;
  overflow-y: hidden;
  height: 0;
  transition: height 200ms ease-in-out;
}

.content {
  position: absolute;
  z-index: 0;
  padding-top: var(--unit_4);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  opacity: 0;
  gap: var(--unit_2) var(--unit_3);
}

.content._active {
  z-index: 1;
  opacity: 1;
}

.content > * {
  padding: var(--unit_1) 0;
}

.link {
  text-decoration: none;
  color: var(--primary_100);
}

.user-card-wrapper {
  width: 100%;
}

@media screen and (min-width: 641px) {
  .content {
    padding-top: var(--unit_5);
  }
}
