.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--unit_4);
}

.wrapper > * {
  white-space: nowrap;
}

.wrapper > *:last-child {
  margin-left: auto;
}
