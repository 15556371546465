.subscribe {
    width: 100%;
    border-radius: 2px;
    background-color: #182930;
    padding: 21px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.subscribe-licence {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    color: white;
}

.subscribe-link {
    text-decoration: none;
    color: #2b87db;
}

.subscribe__text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
    align-self: flex-start;
    font-weight: bold;
}

.subscribe-email {
    text-align: initial;
    position: relative;
    width: 100%;
    margin: 11px 0 12px;
}

.subscribe-email.subscribe-email--license {
    margin-bottom: 0;
}

.subscribe-email__input_val-error {
    border: solid 1px #ff5050;
}

.subscribe-email__val-error {
    display: none;
    position: absolute;
    left: 0;
    padding: 5px 12px 7px 9px;
    border-radius: 4px;
    background-color: #ff5050;
    top: 52px;
    font-size: 14px;
    color: #ffffff;
}

.subscribe-email__val-error:before {
    content: '';
    display: block;
    border: 8px solid transparent;
    border-bottom: 8px solid #ff5050;
    position: absolute;
    top: -16px;
}

.subscribe-email__val-error_visible {
    display: block;
}

.subscribe-email-arrow {
    width: 56px;
    height: 28px;
    border-radius: 42px;
    background-color: #e84260;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 8px;
    cursor: pointer;
}

.subscribe-email-arrow:hover {
    background-color: #bf3755;
}

.subscribe-email-arrow__icon {
    width: 24px;
    height: 14px;
    background: url("img/subscribe-arrow-icon.svg") no-repeat;
    display: flex;
}

.subscribe-done {
    color: #ffffff;
    margin-top: 9px;
    line-height: 1.29;
}

.subscribe-done__header {
    display: block;
}

.subscribe-done-header__icon {
    display: inline-block;
    width: 14px;
    height: 10px;
    margin-right: 6px;
    background: url("img/subscribe-done-icon.svg") no-repeat;
}

.subscribe-err-header__icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 2px 6px 0 0;
    background: url("img/subscribe-err-icon.svg") no-repeat;
}

.subscribe-done__status {
    display: inline-block;
    font-weight: bold;
    width: calc(100% - 20px);
    vertical-align: top;
}

.subscribe-done__text {
    display: block;
    margin: 3px 0 16px 20px;
}

.subscribe-done__replay {
    border-radius: 42px;
    background-color: #e84260;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #ffffff;
    border: none;
    padding: 3px 16px 5px;
    margin: 10px 0 20px 20px;
    cursor: pointer;
    outline: none;
}

.subscribe-done__replay:hover {
    background-color: #bf3755;
}

.subscribe-done__replay:focus {
    transform: scale(.98);
}

@media screen and (max-width: 768px) {
    .subscribe {
        width: 100%;
    }

    .subscribe__text {
        margin-bottom: 15px;
    }

    .subscribe-email,
    .subscribe-done {
        margin-top: 0;
    }
}
