.wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  max-height: 400px;
  cursor: pointer;
}

.title-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: var(--unit_4);
  width: 100%;
  white-space: normal;
}

.rubric-wrapper {
  margin-bottom: var(--unit_4);
}

.title {
  display: inline-flex;
  text-decoration: none;
  color: var(--black_100);
}

.title > * {
  color: inherit;
}

.wrapper img {
  object-position: bottom;
}

@media screen and (min-width: 641px) {
  .wrapper {
    max-height: 450px;
  }

  .title-wrapper {
    padding: var(--unit_6);
  }

  .wrapper img {
    object-position: center calc(85%);
  }
}
