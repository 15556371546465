.advice-card {
  padding: 0 10px;
}

.likes-popup {
  z-index: 1;
}

.dislikes-popup {
  z-index: 1;
}

.advice-card-header_current-nav span {
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7a7a7a;
}
.advice-card-header_current-nav span:first-child {
  margin-right: 16px;
  color: #e84260;
}
.advice-card-header_heading {
  margin-top: 5px;
  margin-bottom: 12px;
  font-size: 32px;
  line-height: 40px;
}
.advice-card-header-author {
}
.advice-card-header-author_avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}
.advice-card-header-author_avatar-outer {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  max-height: 0;
}
.advice-card-header-author_avatar-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.advice-card-header-author_avatar-img {
  height: 100%;
  background-image: url('img/no-image.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 15px;
}
.advice-card-header-author_info {
  display: inline-block;
  min-height: 40px;
  vertical-align: middle;
}
.advice-card-header-author_info-name {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}
.advice-card-header-author_info-descr {
  font-size: 12px;
  color: #7a7a7a;
}
.advice-card-header-author_rating {
  display: inline-block;
  min-height: 40px;
  vertical-align: middle;
}
.advice-card-header-author_rating > svg:first-child {
  margin: 0 8px;
}
.advice-card-header-footerbar {
  position: relative;
  margin-top: 10px;
  padding-bottom: 25px;
  font-size: 12px;
  color: #7a7a7a;
  border-bottom: 1px solid #c9c9c9;
  border-radius: 0.5px;
}
.advice-card-header-footerbar::after {
  display: table;
  clear: both;
  content: '';
}
.advice-card-header-footerbar span {
  line-height: 16px;
}
.advice-card-header-footerbar > a {
  margin-left: 16px;
  padding-left: 20px;
  line-height: 16px;
  text-decoration: none;
  color: #7a7a7a;
  background-image: url('img/icn-viewed.svg');
  background-repeat: no-repeat;
  background-position: left center;
  transition: all 300ms;
}
.advice-card-header-footerbar > a:hover {
  color: #121212;
  background-image: url('img/icn-viewed-hover.svg');
}
.advice-card-header-footerbar .advice-card-header-footerbar_comments {
  margin-left: 14px;
  background-image: url('img/icn-comments.svg');
}
.advice-card-header-footerbar .advice-card-header-footerbar_comments:hover {
  background-image: url('img/icn-comments-hover.svg');
}
.advice-card-header-footerbar button {
  padding: 0 19px;
  display: inline-block;
  float: right;
  width: 72px;
  height: 28px;
  font-size: 12px;
  text-align: right;
  cursor: pointer;
  color: #7a7a7a;
  background-color: white;
  background-image: url('img/like.svg');
  background-repeat: no-repeat;
  background-position: 19px 4px;
  background-size: 16px;
  border: solid 1px #c9c9c9;
  border-radius: 42px;
}

.advice-card-header-footerbar .advice-card-header-footerbar_dislikes {
  margin-left: 10px;
  background-image: url('img/dislike.svg');
  background-position-y: 6px;
}
.advice-card-header-footerbar_likes:hover {
  color: #121212;
  background-image: url('img/like-hover.svg');
  border-color: #121212;
}
.advice-card-header-footerbar_dislikes:hover {
  color: #121212;
  background-image: url('img/dislike-hover.svg');
  border-color: #121212;
}
.advice-card-header-footerbar_likes:focus,
.advice-card-header-footerbar_dislikes:focus {
  outline: none;
  box-shadow: 0 0 2pt 1pt #ff9d00;
}
.advice-card-header-footerbar_like-tip {
  position: absolute;
  top: 40px;
  left: 50%;
  padding: 7px 12px 7px 8px;
  white-space: nowrap;
  background-color: white;
  border: solid 1px #e4e4e4;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  transform: translateX(-50%);
}
.advice-card-header-footerbar_like-tip::before,
.advice-card-header-footerbar_like-tip::after {
  position: absolute;
  top: -20px;
  left: calc(50% - 10px);
  content: '';
  border: 10px solid transparent;
  border-bottom: 10px solid #e4e4e4;
}
.advice-card-header-footerbar_like-tip::after {
  top: -19px;
  border-bottom: 10px solid white;
}
.advice-card-header-footerbar_like-tip-avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  border: solid 0.5px #e4e4e4;
  border-radius: 30px;
}
.advice-card-header-footerbar_like-tip-avatar-outer {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  max-height: 0;
}
.advice-card-header-footerbar_like-tip-avatar-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.advice-card-header-footerbar_like-tip-avatar-img {
  display: block;
  height: 100%;
  background-image: url('img/no-image.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 15px;
}
.advice-card-header-footerbar_like-tip-avatar:not(:first-child) {
  margin-left: 5px;
}
.advice-card-header-footerbar_like-tip-show-all {
  margin-top: 3px;
  margin-left: 16px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  color: #182930;
}
.advice-card-header-footerbar_like-tip-show-all:hover,
.advice-card-header-footerbar_like-tip-show-all:active {
  text-decoration: underline;
  color: #121212;
}
.advice-card-header-editbar {
  padding-top: 13px;
  padding-bottom: 17px;
  border-bottom: 2px solid #121212;
  border-radius: 0.5px;
}
.advice-card-header-editbar a {
  text-decoration: none;
  color: #121212;
}
.advice-card-header-editbar a:hover {
  text-decoration: underline;
}
.advice-card-header-editbar a:not(:first-child) {
  margin-left: 16px;
}
.advice-card-header-editbar a svg {
  margin-right: 5px;
  vertical-align: middle;
}
.advice-card-header-editbar .advice-card-header-editbar_delete {
  padding-left: 15px;
  background: url('img/trash.png') no-repeat left center;
  background-size: 13px;
}
.advice-card-article {
  padding-top: 13px;
  border-bottom: 2px solid #121212;
  border-radius: 0.5px;
}
.advice-card-article p {
  margin: 25px 0;
  font-size: 16px;
  line-height: 24px;
}
.advice-card-article_tags {
  padding-top: 19px;
  padding-bottom: 24px;
}
.advice-card-article_tags span {
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7a7a7a;
}
.advice-card-article_tags span:not(:first-child) {
  margin-left: 12px;
}
.advice-card-article-nav {
  padding-top: 22px;
  padding-bottom: 25px;
}
.advice-card-article-nav_reply {
  padding: 0 16px;
  height: 28px;
  color: white;
  background-color: #2b87db;
  border: 0;
  border-radius: 42px;
  transition: background-color 300ms;
}
.advice-card-article-nav_reply:hover,
.advice-card-article-nav_reply:active {
  background-color: #4608bc;
}
.advice-card-article-nav_likes,
.advice-card-article-nav_dislikes {
  padding: 0 18px;
  display: inline-block;
  min-width: 72px;
  height: 28px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  color: #7a7a7a;
  background-color: white;
  border: solid 1px #c9c9c9;
  border-radius: 42px;
  transition: all 300ms;
}
.advice-card-article-nav_likes-icon,
.advice-card-article-nav_dislikes-icon {
  margin-right: 6px;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.advice-card-article-nav_likes-icon {
  background: url('img/like.svg') no-repeat center;
}
.advice-card-article-nav_dislikes-icon {
  background: url('img/dislike.svg') no-repeat center;
}
.advice-card-article-nav_likes-count,
.advice-card-article-nav_dislikes-count {
  display: inline-block;
  font-weight: bold;
  vertical-align: bottom;
}
.advice-card-article-nav_likes {
  margin-right: 0;
  margin-left: 0;
}
.advice-card-article-nav_dislikes {
  margin-right: 0;
  margin-left: 10px;
}
.advice-card-article-nav_likes:hover,
.advice-card-article-nav_dislikes:hover,
.advice-card-header-footerbar_likes-is-like,
.advice-card-article-nav_likes-liked,
.advice-card-header-footerbar_dislike-is-dislike {
  color: #121212;
  border-color: #999;
}
.advice-card-article-nav_likes-liked .advice-card-article-nav_likes-icon,
.advice-card-article-nav_likes:hover .advice-card-article-nav_likes-icon,
.advice-card-header-footerbar_likes-is-like .advice-card-article-nav_likes-icon {
  background-image: url('img/like-hover.svg');
}
.advice-card-article-nav_likes-liked .advice-card-article-nav_dislikes-icon,
.advice-card-article-nav_dislikes:hover .advice-card-article-nav_dislikes-icon,
.advice-card-header-footerbar_dislike-is-dislike .advice-card-article-nav_dislikes-icon {
  background-image: url('img/dislike-hover.svg');
}
.advice-card-article-nav_likes:focus,
.advice-card-article-nav_dislikes:focus {
  outline: none;
  box-shadow: 0 0 2pt 1pt #ff9d00;
}
.advice-card-article-nav_dislikes-disabled,
.advice-card-article-nav_likes-disabled {
  cursor: default;
}
.advice-card-article-nav_dislikes-disabled:hover,
.advice-card-article-nav_likes-disabled:hover {
  color: #7a7a7a;
  border: solid 1px #c9c9c9;
}
.advice-card-article-nav_dislikes-comments.advice-card-article-nav_dislikes-disabled:hover,
.advice-card-article-nav_likes-comments.advice-card-article-nav_likes-disabled:hover {
  border: 0;
  border-radius: 0;
}
.advice-card-article-nav_dislikes-disabled:focus,
.advice-card-article-nav_likes-disabled:focus {
  box-shadow: none;
}
.advice-card-article-nav_dislikes-disabled:hover .advice-card-article-nav_dislikes-icon {
  background-image: url('img/dislike.svg');
}
.advice-card-article-nav_likes-disabled:hover .advice-card-article-nav_likes-icon {
  background-image: url('img/like.svg');
}
.advice-card-article-nav_complain {
  position: relative;
  padding: 20px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  cursor: pointer;
  color: #7a7a7a;
  background-color: transparent;
  border: 0;
  border-radius: 10px;
  transition: color 300ms;
  /* color: #fff; */
}
.advice-card-article-nav_complain::after {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 24px;
  content: '';
  border: 7px solid transparent;
  border-bottom: 13px solid #7a7a7a;
  border-radius: 2px;
}
.advice-card-article-nav_complain::before {
  position: absolute;
  z-index: 2;
  left: 5px;
  bottom: 21px;
  font-size: 11px;
  font-weight: 800;
  content: '!';
  color: white;
}
.advice-card-article-nav_complain:hover {
  color: #121212;
}
.advice-card-article-nav_complain:hover .advice-card-article-nav_complain::before {
  color: #121212;
}
.advice-card-article-nav-social {
  display: inline-block;
  width: calc(100% - 425px);
  text-align: right;
  vertical-align: middle;
}
.advice-card-article-nav-social button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: url('img/share-vk.svg') no-repeat center center transparent;
  border: 0;
  transition: background 300ms;
}
.advice-card-article-nav-social button:hover,
.advice-card-article-nav-social button:active {
  background-image: url('img/share-vk-hover.svg');
}
.advice-card-article-nav-social button:not(:last-child) {
  margin-right: 8px;
}
.advice-card-article-nav-social .advice-card-article-nav-social_fb {
  background-image: url('img/share-fb.svg');
}
.advice-card-article-nav-social .advice-card-article-nav-social_fb:hover,
.advice-card-article-nav-social .advice-card-article-nav-social_fb:active {
  background-image: url('img/share-fb-hover.svg');
}

.advice-card-article-nav-social .advice-card-article-nav-social_tw {
  background-image: url('img/share-tw.svg');
}
.advice-card-article-nav-social .advice-card-article-nav-social_tw:hover,
.advice-card-article-nav-social .advice-card-article-nav-social_tw:active {
  background-image: url('img/share-tw-hover.svg');
}
.advice-card-article-nav-social .advice-card-article-nav-social_ok {
  background-image: url('img/share-ok.svg');
}
.advice-card-article-nav-social .advice-card-article-nav-social_ok:hover,
.advice-card-article-nav-social .advice-card-article-nav-social_ok:active {
  background-image: url('img/share-ok-hover.svg');
}
.advice-card-article-nav-likebar {
  position: relative;
  margin-left: auto;
  display: flex;
}
.advice-card-modal {
  z-index: 200;
  top: calc(50% - 215px);
  left: calc(50% - 160px);
  padding: 0;
  width: 320px;
  height: 430px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.22);
}
.advice-card-modal-heading {
  padding: 0 30px;
  height: 46px;
  border-bottom: 1px solid #d8d8d8;
}
.advice-card-modal-heading-likebar {
  display: inline-block;
  height: 100%;
}
.advice-card-modal-heading-likebar_helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.advice-card-modal-heading-likebar > div {
  padding-left: 20px;
  display: inline-block;
  height: 20px;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  color: #7a7a7a;
  background-image: url('img/like.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-position-y: 0;
}
.advice-card-modal-heading-likebar > div:nth-child(2) {
  margin-left: 16px;
  background-image: url('img/dislike.svg');
  background-position-y: 4px;
}
/* TODO: add class active onclick JS */
.advice-card-modal-heading-likebar > div.active,
.advice-card-modal-heading-likebar > div:hover,
.advice-card-modal-heading-likebar > div[class*='active'] {
  color: #121212;
  background-image: url('img/like-hover.svg');
}
.advice-card-modal-heading-likebar > div:nth-child(2)[class*='active'],
.advice-card-modal-heading-likebar > div:nth-child(2):hover,
.advice-card-modal-heading-likebar > div:nth-child(2):active {
  background-image: url('img/dislike-hover.svg');
}

/* TODO: add class active onclick JS */
.advice-card-modal-heading-likebar-disabled > div.active,
.advice-card-modal-heading-likebar-disabled > div:hover,
.advice-card-modal-heading-likebar-disabled > div[class*='active'] {
  color: #7a7a7a;
  background-image: url('img/like.svg');
}
.advice-card-modal-heading-likebar-disabled > div:nth-child(2)[class*='active'],
.advice-card-modal-heading-likebar-disabled > div:nth-child(2):hover,
.advice-card-modal-heading-likebar-disabled > div:nth-child(2):active {
  background-image: url('img/dislike.svg');
}

.advice-card-modal-heading-likebar_close {
  display: inline-block;
  float: right;
  width: 14px;
  height: 100%;
  text-decoration: none;
  background: url('img/icn-close.svg') no-repeat center center;
  background-size: contain;
}
.advice-card-modal-heading-likebar_close:hover,
.advice-card-modal-heading-likebar_close:active {
  background-image: url('img/icn-close-hover.svg');
}
/* MODAL */
.advice-card-modal-main {
  overflow-y: auto;
  max-height: 383px;
}
.advice-card-modal-main ul {
  padding-left: 27px;
  list-style: none;
}
.advice-card-modal-main ul li {
  margin: 20px 0;
  height: 40px;
  white-space: nowrap;
}
.advice-card-modal-main-item-avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  border: solid 0.5px #e4e4e4;
  border-radius: 30px;
}
.advice-card-modal-main-item-avatar-outer {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  max-height: 0;
}
.advice-card-modal-main-item-avatar-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.advice-card-modal-main-item-avatar-img {
  display: block;
  height: 100%;
  background-image: url('img/no-image.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 30px;
}
.advice-card-modal-main-item_name {
  padding-left: 12px;
  font-weight: bold;
  line-height: 18px;
  vertical-align: middle;
  text-decoration: none;
  color: #121212;
}
/* likes in comments */
.advice-card-article-nav-likebar-comments {
  position: absolute;
  right: 5px;
  margin: auto 0;
  display: inline-block;
  height: 28px;
}
.advice-card-article-nav_likes-comments,
.advice-card-article-nav_dislikes-comments {
  padding: 0;
  width: auto;
  min-width: auto;
  height: auto;
  text-align: right;
  background: transparent;
  border: 0;
  border-radius: 0;
}
.advice-card-article-nav_likes-comments:focus,
.advice-card-article-nav_dislikes-comments:focus {
  box-shadow: none;
}
.advice-card-article-nav_dislikes-comments {
  margin-left: 16px;
}
.likes-popup-comments {
  left: 50%;
  transform: translateX(-50%);
}
.dislikes-popup-comments {
  left: 50%;
  transform: translateX(-50%);
}

.advice-card-header-footerbar_like-tip__hidden-block {
  position: absolute;
  top: -13px;
  right: 0;
  left: 0;
  margin: auto;
  width: 72px;
  height: 13px;
}

.advice-card-header-footerbar_like-tip__hidden-block-comments {
  top: -24px;
  width: 52px;
  height: 25px;
}

.advice-card-article-nav_likes-wrap,
.advice-card-article-nav_dislikes-wrap {
  position: relative;
  display: inline-block;
}

@media (max-width: 1290px) {
  .advice-card-article-nav-social {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .advice-card {
    padding-top: 40px;
  }
  .advice-card-article-nav-social {
    width: calc(100% - 425px);
    text-align: right;
  }
}
@media (max-width: 639px) {
  .advice-card-header_current-nav span {
    font-size: 10px;
    line-height: 16px;
  }
  .advice-card-header_heading {
    font-size: 22px;
    line-height: 28px;
  }
  .advice-card-header-author_info-desc,
  .advice-card-header-author_info-name {
    font-size: 12px;
    line-height: 16px;
  }
  .advice-card-header-footerbar .advice-card-header-footerbar_likes,
  .advice-card-header-footerbar .advice-card-header-footerbar_dislikes {
    display: none;
  }
  .advice-card-header-editbar > a {
    display: inline-block;
    width: 50%;
  }
  .advice-card-header-editbar > a:not(:first-child) {
    margin-left: 0;
  }
  .advice-card-article_tags {
    border-bottom: 2px solid #121212;
  }
  .advice-card-article-nav {
    position: relative;
    padding-top: 24px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0.5px;
  }
  .advice-card-article-nav_reply {
    margin: 62px auto 0;
    padding: 0 110px;
    display: block;
    height: 40px;
    cursor: pointer;
  }
  .advice-card-article-nav-social {
    margin-top: 28px;
    margin-bottom: 46px;
    width: 100%;
    text-align: center;
  }
  .advice-card-article-nav-likebar {
    position: relative;
    top: 0;
    width: 100%;
  }
  .advice-card-article-nav-likebar button {
    width: 135px;
    height: 40px;
    text-align: center;
  }
  .advice-card-article-nav-likebar .advice-card-article-nav_likes {
    margin-left: 0;
  }
  .advice-card-article-nav-likebar .advice-card-article-nav_dislikes {
    margin-right: 0;
    margin-left: 0;
  }
  .advice-card-article-nav_complain {
    position: relative;
    left: calc(50% - 135px);
    bottom: 4px;
  }
  .advice-card-header-footerbar_like-tip {
    display: none;
  }
  .advice-card-article-nav-likebar-comments {
    right: 0;
    margin-right: 21px;
    width: auto;
    height: auto;
  }
  .advice-card-article-nav-likebar-comments button {
    width: auto;
    height: auto;
  }
  .advice-card-article-nav_dislikes-comments {
    margin-left: 17px;
  }
  .advice-card-article-nav_likes-wrap-card,
  .advice-card-article-nav_dislikes-wrap-card {
    width: calc(50% - 5px);
  }

  .advice-card-article-nav_dislikes-wrap-card {
    margin-left: 10px;
  }

  .advice-card-article-nav_dislikes-wrap-card button,
  .advice-card-article-nav_likes-wrap-card button {
    width: 100%;
  }

  .advice-card-article-nav_dislikes-comments-wrap {
    margin-left: 16px;
  }
}
@media (max-height: 430px) {
  .advice-card-modal {
    top: 0;
    height: 100%;
  }
}
@media (max-width: 320px) {
  .advice-card-modal {
    left: 0;
    width: 100%;
  }
}
