.wrapper {
  display: block;
}

.link {
  padding: 13px var(--unit_6);
  display: block;
  height: 42px;
  font-size: var(--fontSize_12px);
  font-weight: normal;
  line-height: var(--lineHeight_16px);
  text-decoration: none;
  color: var(--white_100);
}

@media screen and (max-width: 1024px) {
  .link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .link._active {
    background: var(--gray60_100);
  }
}

@media screen and (min-width: 1025px) {
  .wrapper {
    margin-bottom: var(--unit_4);
  }

  .link {
    padding: 0 0 0 var(--unit_2);
    height: auto;
    font-size: var(--fontSize_14px);
    font-weight: bold;
    line-height: var(--lineHeight_20px);
    color: var(--black_100);
  }

  .link:hover,
  .link._active {
    color: var(--primary_100);
  }
}
