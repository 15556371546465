.wrapper {
  display: flex;
  align-items: center;
}

.modal-button-wrapper > button {
  padding: 14px;
  display: flex;
  color: var(--gray60_100);
  border: none;
  transition: color var(--transition-option);
}

.modal-button-wrapper > button:not(:disabled):hover {
  color: var(--black_100);
}

.modal-button-wrapper._active > button:not(:disabled) {
  color: var(--primary_100);
}

.ordering-button > button {
  padding: var(--unit_3);
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  cursor: pointer;
  color: var(--gray60_100);
  border: none;
  transition: color var(--transition-option);
}

.ordering-button > button:not(:disabled):hover {
  color: var(--black_100);
}

.ordering-button._active > button:not(:disabled) {
  font-weight: bold;
  color: var(--primary_100);
}

.modal-content {
  background: var(--white_100);
}

.modal-content > * + * {
  border-top: 1px solid var(--gray10_100);
}

.modal-ordering-button {
  padding: var(--unit_3) var(--unit_4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
  cursor: pointer;
}

.modal-ordering-button:hover,
.modal-ordering-button._active {
  background: var(--black_6);
}

.modal-ordering-button > span {
  overflow: hidden;
}

.modal-ordering-button > label {
  margin-left: var(--unit_3);
}

.modal-ordering-button > label > span {
  width: 20px;
  height: 20px;
}

.modal-ordering-button > label > span::before {
  width: 10px;
  height: 10px;
}
