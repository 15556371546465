.wrapper {
  position: relative;
  padding: var(--unit_4) 0;
}

.wrapper::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  content: '';
  border-bottom: 1px solid var(--gray10_100);
}

@media screen and (min-width: 641px) {
  .wrapper {
    padding: var(--unit_6) 0;
  }

  .wrapper::after {
    width: calc(100% - 180px);
  }
}

@media screen and (min-width: 1025px) {
  .wrapper {
    padding: var(--unit_7) 0;
  }
}
