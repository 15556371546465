.wrapper {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.wrapper > *:first-child {
  /* stylelint-disable-next-line declaration-no-important */
  border-bottom-right-radius: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-bottom-left-radius: 0 !important;
}

.title {
  padding: var(--unit_4);
  display: block;
  width: 100%;
  font-size: var(--fontSize_16px);
  font-weight: 500;
  line-height: var(--lineHeight_24px);
  color: var(--white_100);
  background: var(--gray60_100);
  border-bottom-right-radius: var(--unit_1);
  border-bottom-left-radius: var(--unit_1);
}
