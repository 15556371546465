:root {
  --light-background-tertiary: rgba(0, 0, 0, 0.3);
}
.wrapper {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  box-sizing: content-box;
  height: auto;
  user-select: none;
  justify-content: flex-end;
  background: var(--superblack_10);
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
}

.wrapper > *:nth-child(2),
.wrapper > *:nth-child(3) {
  position: absolute;
  z-index: 1;
}

.wrapper > *:nth-child(2)::before,
.wrapper > *:nth-child(3)::before {
  position: absolute;
  z-index: 1;
  bottom: var(--unit_2);
  padding: var(--unit_1) var(--unit_2);
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  color: var(--white_100);
  background: var(--light-background-tertiary);
  border-radius: 4px;
}

.wrapper > *:nth-child(2)::before {
  left: var(--unit_2);
  content: 'Было';
}

.wrapper > *:nth-child(3)::before {
  right: var(--unit_2);
  content: 'Стало';
}

.wrapper img {
  pointer-events: none;
}

.wrapper > *:nth-child(2) img {
  /* stylelint-disable-next-line declaration-no-important */
  height: 100% !important;
  object-position: left;
}

.wrapper > *:nth-child(3) img {
  /* stylelint-disable-next-line declaration-no-important */
  height: 100% !important;
  object-position: right;
}

.control {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  cursor: col-resize;
  background-image: linear-gradient(
    90deg,
    transparent calc(50% - 2px),
    var(--white_100) calc(50% - 2px),
    var(--white_100) calc(50% + 2px),
    transparent calc(50% + 2px)
  );
  background-repeat: no-repeat;
}

.control::before,
.control::after {
  position: absolute;
  top: 50%;
  content: '';
  border-width: 9px;
  border-style: solid;
  border-color: transparent;
  transform: translateY(-50%);
}

.control::before {
  right: calc(50% + 8px);
  border-right-color: var(--white_100);
}

.control::after {
  left: calc(50% + 8px);
  border-left-color: var(--white_100);
}

.back-image {
  visibility: hidden;
  opacity: 0;
}

@media screen and (min-width: 641px) {
  .wrapper > *:nth-child(2)::before,
  .wrapper > *:nth-child(3)::before {
    bottom: var(--unit_4);
    font-size: var(--fontSize_16px);
    line-height: var(--lineHeight_24px);
  }

  .wrapper > *:nth-child(2)::before {
    left: var(--unit_4);
  }

  .wrapper > *:nth-child(3)::before {
    right: var(--unit_4);
  }
}
