.wrapper {
  display: flex;
  align-items: center;
}

.button-wrapper > button {
  padding: 14px;
  display: flex;
  color: var(--gray60_100);
  border: none;
  transition: color var(--transition-option);
}

.button-wrapper > button:not(:disabled):hover {
  color: var(--black_100);
}

.button-wrapper._active > button:not(:disabled) {
  color: var(--primary_100);
}
