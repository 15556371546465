.wrapper + .wrapper {
  margin-top: var(--unit_4);
}

.title {
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  text-decoration: none;
  color: var(--black_100);
}

.title:hover {
  color: var(--fill_primary_hover_100);
}

.title:active {
  color: var(--fill_primary_active_100);
}

.date {
  margin-top: var(--unit_2);
  display: block;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  color: var(--gray60_100);
}

@media screen and (max-width: 640px) {
  .wrapper > a {
    display: block;
  }
}
