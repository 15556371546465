@import '@cian/ui-kit/colors/variables.css';

.suggests {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 0;
  left: 0;
  margin-top: 4px;
  padding: 4px 0;
  overflow-y: hidden;
  box-sizing: border-box;
  max-height: 188px;
  background: white;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px var(--superblack_10);
}

.suggest {
  padding: 8px 12px;
  cursor: pointer;
}

.suggest:hover {
  background-color: var(--black_6);
}
