.wrapper {
  margin: var(--unit_8) 0;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-wrapper > * {
  /* stylelint-disable-next-line declaration-no-important */
  margin-bottom: var(--unit_4) !important;
  /* stylelint-disable-next-line declaration-no-important */
  display: flex !important;
}

.button-wrapper {
  margin-top: var(--unit_4);
}

.button-wrapper > a {
  width: 100%;
}

@media screen and (min-width: 641px) {
  .wrapper {
    margin: var(--unit_10) 0;
  }

  .title-wrapper > a {
    /* stylelint-disable-next-line declaration-no-important */
    margin-left: var(--unit_4) !important;
  }

  .title-wrapper > * {
    /* stylelint-disable-next-line declaration-no-important */
    margin-bottom: var(--unit_6) !important;
  }

  .button-wrapper > a {
    width: auto;
  }
}
