.seo-urls {
    background-color: #f4f4f4;
    padding: 37px 0 0;
    padding-bottom: 20px;
    display: none;
    width: 100%;
}

.seo-urls-dekstop {
    display: block;
}

.seo-urls__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 21px;
    border-bottom: 2px solid #182930;
    letter-spacing: 1px;
}

.seo-urls__header_text {
    margin: 0;
    font-size: 22px;
    line-height: 1.27;
    text-transform: uppercase;
}

.seo-urls__list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.seo-urls__item {
    padding: 24px 0;
    display: block;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.27;
    color: #121212;
    text-decoration: none;
    border-bottom: 1px solid #e4e4e4;
}

@media screen and (max-width: 768px) {
    .seo-urls {
        padding-top: 21px;
    }

    .seo-urls__content {
        padding: 0 20px;
    }

    .seo-urls-dekstop {
        display: none;
    }

    .seo-urls-mobile {
        display: block;
    }

    .seo-urls__header {
        padding-bottom: 17px;
    }

    .seo-urls__header_text {
        font-size: 14px;
        line-height: 1.43;
    }

    .list-content {
        margin-bottom: 20px;
    }

    .seo-urls__item {
        padding: 16px 0;
        font-size: 18px;
        line-height: 1.33;
    }
}
