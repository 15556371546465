.wrapper {
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
  gap: var(--unit_4);
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
}

.buttons {
  margin-top: auto;
  display: flex;
  align-items: flex-start;
  gap: var(--unit_2);
}

.buttons._readonly > * {
  cursor: auto;
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
}

.buttons._readonly > *:first-child {
  /* stylelint-disable-next-line declaration-no-important */
  background: var(--primary_100) !important;
}

.buttons._readonly > *:last-child {
  /* stylelint-disable-next-line declaration-no-important */
  background: var(--white_100) !important;
}
