.wrapper {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header-link {
  margin-top: var(--unit_10);
  margin-bottom: var(--unit_2);
  display: inline-block;
  text-decoration: none;
  color: var(--black_100);
}

.header-link:hover > * {
  color: var(--fill_primary_hover_100);
}

.header-link:active > * {
  color: var(--fill_primary_active_100);
}

.items-wrapper {
  padding: var(--unit_5) 0;
  overflow-y: auto;
}

.more-link-wrapper {
  position: relative;
}

.more-link {
  padding: var(--unit_2) 0;
  display: block;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  text-decoration: none;
  color: var(--primary_100);
}

.more-link:hover,
.more-link:active {
  text-decoration: underline;
  color: var(--fill_primary_hover_100);
}

.gradient {
  position: absolute;
  z-index: 1;
  top: calc(var(--unit_4) * -1);
  width: 100%;
  height: var(--unit_5);
  background: linear-gradient(180deg, transparent 0%, var(--white_100) 90%);
}

@media screen and (min-width: 641px) {
  .header-link {
    margin-top: 0;
  }
}
