.tooltip-container {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.tooltip {
    border-radius: 4px;
    background-color: #ff5050;
    padding: 5px 12px 7px 9px;
    line-height: 1.43;
    color: #fff;
    position: absolute;
    top: 0;
    right: -11px;
    max-width: 290px;
    transform: translateX(100%);
    z-index: 1;
}
.tooltip::before {
    content: '';
    display: block;
    border: 6px solid transparent;
    border-right: 6px solid #ff5050;
    position: absolute;
    top: 10px;
    left: -12px;
}

@media (max-width: 768px) {
    .tooltip {
        min-height: 25px;
        position: absolute;
        transform: translate(0);
        right: 0;
        top: 100%;
        max-width: none;
        width: 100%;
        text-align: center;
        padding: 2px 0 3px;
        border-radius: 3px;
    }

    .tooltip::before {
        display: none;
    }
}
