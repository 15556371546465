.inner-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--unit_2);
  text-decoration: none;
}

.inner-wrapper:hover * {
  color: var(--primary_100);
}

.inner-wrapper > *:nth-child(2) {
  min-width: 72px;
  max-width: 72px;
}

.footnote {
  margin-top: var(--unit_2);
}

.footnote * {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--gray60_100) !important;
}
