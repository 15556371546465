.wrapper {
  overflow: hidden;
  width: 100%;
  height: 273px;
}

.empty-wrapper {
  overflow: hidden;
  width: 100%;
  height: 0;
}

.wrapper-100 {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
