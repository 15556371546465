.wrapper {
  margin-bottom: calc(var(--unit_4) * -1);
  display: flex;
  align-items: center;
  gap: var(--unit_6);
}

.tab {
  padding: var(--unit_2) 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab._active {
  border-bottom-color: var(--icon-main-default);
}

/* расстояние между иконкой и каунтом */
/* stylelint-disable-next-line selector-max-compound-selectors */
.tab > * > * + * {
  margin-left: var(--unit_2);
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.icon-wrapper > svg {
  color: var(--light-text-seconary);
}

.tab._active * {
  color: var(--icon-main-default);
}

@media screen and (min-width: 641px) {
  .wrapper {
    margin-bottom: calc(var(--unit_3) * -1);
  }
}
