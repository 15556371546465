.wrapper {
  display: inline-flex;
}

.wrapper > a {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: var(--white_100) !important;
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
}

.wrapper > a:hover,
.wrapper > a._hover {
  border-color: var(--black_100);
}

.wrapper._active > a {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: var(--gray10_100) !important;
  border-color: var(--black_100);
}

.wrapper > a > span {
  font-weight: normal;
}
