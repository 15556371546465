.wrapper {
  padding-top: var(--unit_6);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: var(--black_100);
  animation: flash 0.5s ease-in forwards;
}

.wrapper svg {
  margin-bottom: var(--unit_6);
  width: 150px;
  min-width: 150px;
  height: 150px;
  min-height: 150px;
}

.title {
  margin-bottom: var(--unit_1);
}

.description {
  margin-bottom: var(--unit_1);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
}

.description > a {
  text-decoration: none;
  color: var(--primary_100);
}

.description > a:hover {
  color: var(--black_100);
}

@media screen and (min-width: 641px) {
  .wrapper {
    padding-top: var(--unit_12);
  }

  .wrapper svg {
    margin-bottom: var(--unit_10);
    width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;
  }
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
