.list-item {
  padding: 0 0 24px;
  word-wrap: break-word;
  border-bottom: 1px solid #e4e4e4;
}

.list-item__content {
  position: relative;
  display: inline-block;
  width: calc(100% - 206px);
  vertical-align: top;
}

.list-item__content-full {
  width: 100%;
}

.list-item__link_img {
  margin-top: 20px;
  margin-left: 14px;
  display: inline-block;
  max-height: 139px;
  vertical-align: top;
}

.list-item__category {
  margin-top: 16px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e84260;
}

.list-item__category:hover {
  color: #121212;
}

.list-item__category-container {
  margin-right: 4px;
}

.list-item__category-container a {
  margin-right: 8px;
}

.list-item__category-container a:last-child:not(:first-child) {
  color: #7a7a7a;
}

.list-item__category-container a:hover:last-child {
  color: #121212;
}

.list-item__category-empty {
  margin: 16px 0 5px;
  display: inline-block;
}

.list-item__ads-caption {
  margin-left: 13px;
  padding: 0 5px;
  display: inline-block;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  background-color: #e84260;
  border-radius: 2px;
}

.list-item__title {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.27;
  text-decoration: none;
  color: #121212;
}

.list-item__link {
  text-decoration: none;
}
.list-item__description-wrap {
  margin: 8px 0 10px;
}
.list-item__description {
  font-size: 14px;
  line-height: 1.43;
  word-wrap: break-word;
  color: #121212;
}

.list-item__img img {
  display: block;
  width: 186px;
  height: 139px;
  flex: 0 0 186px;
}

.list-item__img-mobile {
  display: none;
}

.list-item__img-mobile img {
  display: none;
  flex: 0 0 186px;
  width: 280px;
  height: 186px;
}

.list-item__link_img-visited,
.list-item__link_category-visited {
  opacity: 0.5;
}

.list-item__link-visited {
  color: #7a7a7a;
}

.list-item__title:hover,
.list-item__title-hover {
  color: #e84260;
}

.views-icon,
.comments-icon,
.votes-icon {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  font-size: inherit;
  vertical-align: middle;
}

.views-icon {
  background: url('img/viewed.svg') no-repeat center;
}

.comments-icon {
  background: url('img/comments.svg') no-repeat center;
}

.votes-icon {
  background: url('img/votes.svg') no-repeat center;
}

.list-item__comments:hover {
  cursor: pointer;
  color: #121212;
}

.list-item__comments:hover .comments-icon {
  background: url('img/comments-hover.svg') no-repeat center;
}

.list-item__views,
.list-item__votes {
  margin-right: 14px;
}

.list-item__date {
  margin-right: 16px;
}

.list-item__comments,
.list-item__views,
.list-item__date,
.list-item__votes {
  display: inline-block;
}

.list-item__comments {
  text-decoration: none;
  color: #7a7a7a;
}

.post-info {
  font-size: 12px;
  line-height: 1.3;
  color: #7a7a7a;
}

.post-info_author {
  display: inline-flex;
  align-items: center;
}

.post-info_author__img {
  margin-right: 10px;
  display: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.post-info_author__name {
  margin-right: 16px;
  font-weight: 600;
  white-space: pre-line;
  color: #7a7a7a;
}

@media screen and (max-width: 540px) {
  .list-item {
    margin-bottom: 0;
    padding: 16px 0 24px;
    flex-direction: column-reverse;
    background: transparent;
    border-width: 0 0 1px;
  }

  .list-item__content,
  .list-item__link_img {
    width: 100%;
  }

  .list-item__img {
    margin: 0 auto;
    display: none;
    max-width: 100%;
    height: auto;
  }

  .list-item__img-mobile {
    display: block;
  }

  .list-item__img-mobile img {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
  }

  .list-item__link_img {
    margin: 8px 0 16px;
    max-height: none;
  }

  .list-item__category {
    margin-top: 0;
    font-weight: 900;
  }

  .list-item__title {
    font-size: 18px;
  }

  .views-icon {
    width: 18px;
  }

  .views-icon::before {
    top: 1px;
    width: 11px;
    height: 11px;
    background: #7a7a7a;
    border: none;
  }

  .views-icon::after {
    top: 4px;
    left: 5px;
    width: 5px;
    height: 5px;
    background: #f4f4f4;
    border: none;
  }

  .comments-icon::before {
    border-radius: 2px;
    transform: none;
  }

  .comments-icon__dot {
    display: none;
  }

  .post-info__author {
    margin: 0;
    display: inline-block;
  }

  .post-info__author::after {
    content: '|';
  }
}
