.wrapper {
  display: block;
}

.inner-wrapper {
  padding: 0;
  list-style: none;
}

@media screen and (min-width: 1025px) {
  .wrapper {
    position: absolute;
    top: -1px;
    left: calc(100% - 16px);
    width: 246px;
    min-width: 246px;
    height: calc(100% + 1px);
  }

  .inner-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 75px 15px var(--unit_6) var(--unit_6);
    width: calc(100% - 16px);
    height: 100%;
    background: var(--white_100);
    border: 1px solid var(--gray10_100);
  }
}
