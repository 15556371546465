.wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.wrapper > .item-wrapper:hover {
  z-index: 2;
}

.item-wrapper {
  z-index: 1;
}

.item-wrapper > * {
  border: 1.5px solid var(--light-surface-neutral);
}
