.advice-list-article_decline-reason {
  margin: 10px 0;
  padding: 12px 14px 14px;
  background-color: #ffeaee;
  border-radius: 3px;
}
.advice-list-article_decline-reason h5 {
  margin: 0;
  font-size: 10px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7a7a7a;
}
