.wrapper h2,
.wrapper h3,
.wrapper h4,
.wrapper h5 {
  font-weight: bold;
  letter-spacing: -0.5px;
}

.wrapper h2 {
  font-size: var(--fontSize_30px);
  line-height: var(--lineHeight_36px);
}

.wrapper h3 {
  font-size: var(--fontSize_26px);
  line-height: var(--lineHeight_32px);
}

.wrapper h4 {
  font-size: var(--fontSize_22px);
  line-height: var(--lineHeight_28px);
}

.wrapper h5 {
  font-size: var(--fontSize_18px);
  line-height: var(--lineHeight_24px);
}

.wrapper h6 {
  font-size: var(--fontSize_16px);
  font-weight: bold;
  line-height: var(--lineHeight_22px);
  letter-spacing: -0.2px;
}

.wrapper p {
  font-size: var(--fontSize_18px);
  line-height: var(--lineHeight_28px);
  word-break: break-word;
  overflow-wrap: break-word;
}

.wrapper ol,
.wrapper ul {
  font-size: var(--fontSize_18px);
  line-height: var(--lineHeight_28px);
}

.text-secondary {
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
}
