:root {
  --sidebar-width-desktop: 240px;
}

.sections-wrapper {
  position: relative;
}

.sections-wrapper._is-search-shown {
  filter: blur(2px);
}

.wrapper {
  min-width: 320px;
}

.main-area {
  padding: 0 var(--unit_4);
}

.search-and-regions {
  display: none;
}

.pre-footer {
  margin-top: 60px;
  display: block;
  background: #f4f4f4;
}

.pre-footer .wrapper {
  position: initial;
  padding-right: var(--unit_5);
  padding-left: var(--unit_5);
  display: block;
}

.pre-footer-main-area {
  display: flex;
  flex-direction: column;
  gap: var(--unit_5);
}

.pre-footer-main-area > *:first-child {
  margin-top: var(--unit_8);
}

.pre-footer-sidebar-area > *:last-child {
  margin-bottom: var(--unit_8);
}

.adfox-banner-footer {
  padding-top: var(--unit_10, 40px);
  padding-bottom: var(--unit_8, 32px);

  &:empty {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .wrapper {
    position: relative;
    margin: 0 auto;
    padding-left: 0.5rem;
    display: flex;
    max-width: 1376px;
  }

  .sidebar {
    z-index: 1;
    width: var(--sidebar-width-desktop);
    min-width: var(--sidebar-width-desktop);
  }

  .main-area {
    z-index: 0;
    padding: 0 var(--unit_5) 0 0;
    width: calc(100% - var(--sidebar-width-desktop));
  }

  .search-and-regions {
    padding: var(--unit_6) 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray10_100);
  }

  .search-and-regions > * + * {
    margin-left: var(--unit_4);
  }

  .search-and-regions > *:first-child {
    width: 100%;
  }

  .search-and-regions > *:last-child {
    margin-left: auto;
    white-space: nowrap;
  }

  .pre-footer {
    margin-left: -0.5rem;
  }

  .pre-footer .wrapper {
    padding-right: calc(var(--unit_5) - 0.5rem);
  }

  .pre-footer-main-area {
    margin-left: calc(var(--sidebar-width-desktop) - var(--unit_5));
    flex-direction: row;
    gap: var(--unit_5);
  }

  .pre-footer-sidebar-area {
    width: 32%;
    min-width: 300px;
    max-width: 356px;
  }

  .pre-footer-main-area > * {
    margin-top: var(--unit_8);
  }

  .pre-footer-sidebar-area > * {
    margin-bottom: var(--unit_8);
  }
}
