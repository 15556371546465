.header-wrapper {
  margin-bottom: var(--unit_4);
}

.link {
  text-decoration: none;
  color: var(--primary_100);
}

@media screen and (min-width: 641px) {
  .header-wrapper {
    margin-bottom: var(--unit_6);
  }
}
