.post_card__schema {
  display: none;
}

.post_card {
  background: var(--white_100);
}

.header__top {
  height: 40px;
  background-color: #475258;
}

.header__bottom {
  height: 70px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.author-container {
  margin-bottom: 15px;
  display: block;
}

.footer__top {
  height: 330px;
}

.footer__bottom {
  height: 45px;
  background-color: #4a5258;
}

.post-card-top-likes {
  margin-left: auto;
}

.card-footer-container {
  margin-top: 24px;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
}

.card-footer-container-question {
  min-height: 28px;
}

.advice-card-article-nav_complain-wrap,
.advice-card-article-nav_complain-wrap-mobile {
  position: relative;
  margin-left: 20px;
}

.advice-card-article-nav_complain-wrap-mobile {
  display: none;
}

.advice-card-article-nav_complain-question,
.advice-card-article-nav_complain-wrap-question {
  margin-left: 0;
}

.post-card-info {
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-wrap: wrap;
  color: #7a7a7a;
}

.post-card-info__author {
  margin-right: 16px;
  font-weight: 600;
}

.post-card-info__date {
  margin-right: 16px;
  white-space: nowrap;
}

.post-card-info__views,
.post-card-info__votes {
  margin-right: 14px;
  display: block;
}

.post-card-info__comments {
  margin-right: 16px;
  display: block;
  cursor: pointer;
}

.post-card-info__comments:hover {
  color: #121212;
}

.post-card-info__comments:hover .comments-icon {
  background: url('img/comments-hover.svg');
}

.post-card-content {
  margin-top: 29px;
  /* overflow: hidden; */
}

.post-card-content-blog-question {
  margin-top: 24px;
}

.post-card-content * {
  font-size: 16px;
  line-height: 1.5;
}

.post-card-content h2,
.post-card-content h3,
.post-card-content h4,
.post-card-content h5 {
  margin-bottom: var(--unit_6);
  font-weight: bold;
  letter-spacing: -0.5px;
}

.post-card-content h2,
.post-card-content h2 * {
  font-size: var(--fontSize_26px);
  line-height: var(--lineHeight_36px);
}

.post-card-content h3,
.post-card-content h3 * {
  font-size: var(--fontSize_24px);
  line-height: var(--lineHeight_32px);
}

.post-card-content h4,
.post-card-content h4 * {
  font-size: var(--fontSize_22px);
  line-height: var(--lineHeight_28px);
}

.post-card-content h5,
.post-card-content h5 * {
  font-size: var(--fontSize_18px);
  line-height: var(--lineHeight_24px);
}

.post-card-content h6 {
  margin-bottom: var(--unit_4);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  letter-spacing: -0.2px;
}

.post-card-content h6 * {
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
}

.post-card-content__main-img-container {
  position: relative;
  margin-bottom: 22px;
}

.post-card-content__main-img-container::before {
  padding-top: 66.66%;
  display: block;
  width: 100%;
  content: '';
}

.post-card-content__main-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-card-content__summary {
  margin: 0 0 27px;
  padding-bottom: 32px;
  font-size: 20px;
  line-height: 1.4;
  border-bottom: 2px solid #182930;
}

.post-card-content__summary-empty {
  margin-bottom: 38px;
  padding-bottom: 0;
}

.post-card-content a:not([role='button']) {
  text-decoration: none;
  color: #2371d2;
}

.post-card-content a:not([role='button']):hover {
  color: #df294e;
}

.post-card-content a:not([role='button']):visited {
  color: #df294e;
}

.image-wrap {
  position: relative;
  margin: 34px 0 19px;
  width: 100%;
}

.image-wrap__image {
  margin: 0;
  display: block;
  width: 100%;
}

.image-wrap__description {
  margin: 0;
  padding-top: 7px;
  display: block;
  font-size: 12px;
  line-height: 1.33;
  color: #7a7a7a;
}

.inset {
  margin: 32px 0;
  padding-left: 24px;
  box-sizing: border-box;
  border-left: 2px solid #e84260;
}

.inset p:first-child {
  margin-top: 0;
}

.inset__main-info,
.inset__secondary-info {
  margin: 0;
  display: block;
  font-size: 14px;
  line-height: 1.43;
}

.inset__main-info {
  font-weight: 600;
}

.inset__secondary-info {
  color: #676767;
}

.tags {
  margin-top: 28px;
  padding-bottom: 24px;
  font-size: 12px;
  line-height: 1.67;
  border-bottom: 2px solid #121212;
}

.tags__title,
.tags__link {
  font-weight: 600;
}

.tags__title {
  margin-right: 6px;
}

.tags__link {
  margin: 0 6px;
  display: inline-block;
  text-decoration: none;
  color: #7a7a7a;
}

.tags__link:hover {
  color: #121212;
}

.post-card-title {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  word-wrap: break-word;
  color: #121212;
}

.post-card-info-wrap {
  margin-top: 15px;
}

.post-card-info-wrap-blog-question {
  margin-top: 12px;
}

.views-icon,
.comments-icon,
.blogs-icon,
.votes-icon {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.comments-icon {
  background: url('img/comments.svg') no-repeat center;
}

.views-icon {
  background: url('img/viewed.svg') no-repeat center;
}

.blogs-icon {
  background: url('img/blog.svg') no-repeat center;
}

.votes-icon {
  background: url('img/votes.svg') no-repeat center;
}

.blogs-link {
  text-decoration: none;
  color: #7a7a7a;
}

.blogs-link:hover {
  color: #121212;
}

.blogs-link:hover .blogs-icon {
  background: url('img/blog-hover.svg') no-repeat center;
}

.category-name {
  margin: 18px 16px 3px 0;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e84260;
}

.category-name__empty {
  margin: 18px 0 3px;
  display: inline-block;
}

.subcategory-name {
  margin: 0 8px 0 0;
  font-weight: bold;
  line-height: 1.43;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7a7a7a;
}

.subcategory-name:hover {
  color: #121212;
}

.caption {
  margin-right: 12px;
  padding: 0 5px;
  display: inline-block;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--white_100);
  border: 0;
  border-radius: 3px;
}
.ads-caption {
  background-color: #e84260;
}
.accepted-caption {
  background-color: #7fbe70;
}
.decline-caption {
  background-color: #ff5050;
}
.draft-caption {
  background-color: #d6d2d2;
}
.category-name:hover {
  color: #121212;
}

.post-card-content__text {
  padding-bottom: 12px;
  /* overflow-x: auto; */
  overflow-y: visible;
  word-wrap: break-word;
}

.post-card-content__text > p:first-child {
  margin-top: 0;
}

.post-card-content__text table {
  border-spacing: 0;
}

.post-card-content__text table td:first-child {
  border-left: 1px solid #ddd;
}

.post-card-content__text table td {
  margin: 0;
  padding: 8px;
  border: 1px solid #ddd;
  border-top: 0;
  border-left: 0;
}

.popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(24, 41, 48, 0.8);
  justify-content: center;
  align-items: center;
}

.popup__close-icon {
  position: absolute;
  top: 13px;
  right: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url('img/close-popup-icon.svg');
}

.popup img {
  max-width: 100%;
  max-height: 100%;
}

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.post-card-content-blog-question-empty {
  margin-bottom: 14px;
  border-bottom: 0;
}

.post-card-content__text img {
  /* stylelint-disable-next-line declaration-no-important */
  height: auto !important;
}

.post-card-content__summary-empty-permision-invalid {
  border-bottom: 2px solid #182930;
}

.post-card-content__link-btn {
  margin-right: 24px;
  padding: 3px 16px 5px;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--white_100) !important;
  background-color: #2b87db;
  border: 0;
  border-radius: 42px;
  outline: none;
}

.recommended-offers-wrapper {
  margin-top: var(--unit_5);
}

.author-articles-wrapper {
  margin-top: var(--unit_6);
  padding-bottom: var(--unit_6);
}

@media screen and (max-width: 1024px) {
  .post_card {
    padding: 20px 0 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .post_card {
    margin-bottom: 60px;
    padding: 0 20px;
    width: 100%;
  }

  .caption {
    padding: 0 5px;
    font-size: 10px;
    font-weight: 900;
    line-height: 1.6;
  }
}

@media screen and (max-width: 639px) {
  .post-card-info__author {
    display: inline-block;
  }

  .views-icon {
    width: 18px;
  }

  .views-icon::before {
    top: 1px;
    width: 11px;
    height: 11px;
    background: #7a7a7a;
    border: none;
  }

  .views-icon::after {
    top: 4px;
    left: 5px;
    width: 5px;
    height: 5px;
    background: #f4f4f4;
    border: none;
  }

  .comments-icon__dot {
    display: none;
  }

  .post-card-content * {
    font-size: 16px;
  }

  .post-card-content img {
    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 auto 12px !important;
    /* stylelint-disable-next-line declaration-no-important */
    width: 100% !important;
  }

  .post-card-content__summary {
    margin-top: 20px;
    margin-bottom: 21px;
    padding-bottom: 24px;
    font-size: 16px;
    line-height: 1.5;
    border-bottom: 2px solid #182930;
  }

  .image-wrap {
    margin-top: 23px;
  }

  .inset {
    margin: 20px 0;
    padding-left: 24px;
    border-left: 2px solid #e84260;
  }

  .inset__photo {
    display: none;
  }

  .inset__content * {
    font-size: 14px;
    font-style: normal;
  }

  .inset__main-info,
  .inset__secondary-info {
    font-size: 12px;
  }

  .inset__main-info {
    font-weight: 600;
  }

  .inset__secondary-info {
    color: #676767;
  }

  .category-name {
    margin: 16px 14px 5px 0;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .subcategory-name {
    margin: 0 14px 0 0;
    font-size: 10px;
    font-weight: 900;
    line-height: 1.6;
    text-decoration: none;
    letter-spacing: 0.7px;
  }

  .post-card-info-wrap {
    margin-top: 0;
  }

  .post-card-title {
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 1.3;
  }

  .post-card-content {
    margin-top: 25px;
  }

  .post-card-content__text table {
    display: block;
    overflow: auto;
  }

  .card-footer-container {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
  }

  .post-card-top-likes {
    display: none;
  }

  .author-container {
    margin-top: 11px;
  }

  .post-card-content__main-img-container {
    margin-top: 0;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .advice-card-article-nav_complain-wrap {
    display: none;
  }

  .advice-card-article-nav_complain-wrap-mobile {
    margin-left: 0;
    padding-bottom: 27px;
    display: block;
    border-bottom: 1px solid #e4e4e4;
  }

  .post-card-info-wrap-blog-question {
    margin-top: 11px;
  }

  .post-card-content-blog-question-empty {
    margin-top: 0;
    padding: 0;
  }

  .post-card-content__summary-empty-permision-invalid {
    padding: 0;
  }

  .post-card-content__summary-empty-permision-valid-mobile {
    margin-bottom: 0;
    border: 0;
  }
  .post-card-content-blog-question {
    margin-top: 31px;
  }

  .post-card-content__text {
    margin: 0;
  }

  .advice-card-article-nav_complain-mobile {
    padding-left: 0;
  }
}
