.wrapper {
  display: flex;
  justify-content: space-between;
}

.content-wrapper {
  width: 100%;
}

.content-wrapper > * + * {
  margin-top: var(--unit_4);
}

.headnote {
  display: flex;
  align-items: center;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--gray60_100);
}

.headnote > * + * {
  margin-left: var(--unit_4);
}

.status-wrapper {
  margin-left: auto;
}

.title {
  display: block;
  font-size: var(--fontSize_22px);
  font-weight: bold;
  line-height: var(--lineHeight_28px);
  text-decoration: none;
  letter-spacing: -0.5px;
  color: var(--black_100);
  transition: color 200ms ease-in-out;
}

.title:hover {
  color: var(--primary_100);
}

.subtitle {
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  color: var(--gray60_100);
}

.image-wrapper {
  margin-left: var(--unit_5);
  display: flex;
  flex-direction: column;
  gap: var(--unit_2);
  flex: 0 0 auto;
  width: 33%;
}

.image {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}

@media screen and (min-width: 641px) {
  .wrapper {
    max-height: 250px;
  }
}

@media screen and (min-width: 1025px) {
  .title {
    font-size: var(--fontSize_28px);
    line-height: var(--lineHeight_36px);
  }
}
