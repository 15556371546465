.wrapper {
  position: fixed;
  z-index: var(--zIndex_popover);
  bottom: 0;
  padding: var(--unit_4);
  width: 100%;
  background: var(--white_100);
  transition-timing-function: ease-in-out;
  transition-property: transform;
  transform: translateY(100%);
}

.wrapper._top {
  transform: translateY(0);
}
