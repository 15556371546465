.wrapper {
  position: relative;
}

.mobile-menu-inner-wrapper {
  overflow-y: auto;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    z-index: var(--zIndex_modal);
  }

  .backdrop-wrapper > .backdrop {
    background-color: var(--superblack_100);
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  .backdrop-wrapper._shown > .backdrop {
    opacity: 0.4;
    transition: opacity 0.1s ease-in;
  }

  /* вставляем в body как backdrop */
  .backdrop {
    position: absolute;
    z-index: calc(var(--zIndex_modal) - 1px);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .wrapper {
    position: sticky;
    top: 0;
    padding-top: var(--unit_5);
  }

  .b2b-mode-switcher-wrapper {
    margin: var(--unit_3) var(--unit_6);
  }
}
