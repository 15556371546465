.survey {
  font-family: 'Lato', Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 736px;
  max-height: fit-content;
  background: linear-gradient(64.4deg, #0468FF -35.98%, #FFE1FF 98.23%);
  color: #152242;
  padding: 60px 88px;
  border-radius: 8px;
  box-sizing: border-box;
}

.survey__title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 40px;
}

.survey__title_result {
  margin: 0 0 48px;
}

.survey__options {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.survey__quantity {
  position: absolute;
  top: -48px;
  left: 0;
  font-size: 14px;
  line-height: 2;
  color: #fff;
  opacity: 0.7;
}

.survey__input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.survey__option-wrapper {
  margin-bottom: 24px;
}

.survey__option {
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: 16px;
  line-height: 1.4;
  padding: 10px 20px 11px;
  border-radius: 4px;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.survey__input:checked + .survey__option {
  background-color: #FFE1FF;
}

.survey__option:last-of-type, .survey__option-wrapper:last-of-type {
  margin-bottom: 0;
}

.survey__option::before {
  content: '';
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 1px solid #CED1D7;
  border-radius: 50%;
  box-sizing: border-box;
}

.survey__input:checked + .survey__option::before {
  border: 5px solid #0468FF;
}

.survey__option_result::before {
  display: none;
}

.survey__option_multiple::before {
  width: 20px;
  height: 20px;
  padding: 1px;
  border-radius: 2px;
}

.survey__input:checked + .survey__option::before {
  border: none;
  background-color: #075AD9;
  background-image: url("data:image/svg+xml;utf8,<svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3.31907 7.35444L4.12708 8L4.8143 7.22981L10 1.41805L8.37314 0L3.80191 5.12263L1.36361 3.03537L0 4.70268L3.31907 7.35444Z' fill='white'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
}

.survey__button {
  flex-grow: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  padding: 9px 16px;
  margin: 32px auto 0;
  color: #0468FF;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.survey__button:focus {
  box-shadow: 0 0 1px 1px rgb(131 180 255);
}

.survey__button:hover {
  background-color: #e7f0ff;
}

.survey__button:active {
  background-color: #d1dbeb;
}

.survey__emoji {
  width: 33px;
  height: 33px;
  margin-right: 8px;
}

.survey__option-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /* width должна приходить динамически */
  width: 30%;
  background-color: #FFE1FF;
  border-radius: 4px 0 0 4px;
}

.survey__option .survey__option-text, .survey__option .survey__emoji {
  position: relative;
  z-index: 1;
}

.survey__option.isChecked .survey__option-text {
  font-weight: 700;
}

.survey__option-text {
  max-width: 80%;
}

.survey__percent {
  font-weight: 700;
  display: inline-block;
  margin-left: auto;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .survey {
    max-width: 656px;
    padding: 60px 48px;
  }
}

@media screen and (max-width: 540px) {
  .survey {
    padding: 40px 16px;
  }

  .survey__title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .survey__title {
    margin-bottom: 40px;
  }

  .survey__option {
    margin-bottom: 16px;
    padding: 8px 16px 8px 8px;
  }

  .survey__quantity {
    top: -40px;
  }
}
