.wrapper {
  background: linear-gradient(270deg, #c1e8fd 0%, #ffe1ff 99.68%);
  border-radius: 8px;
}

.content-wrapper {
  padding: var(--unit_4);
  color: var(--primary_100);
}

.button-wrapper {
  padding: 0 var(--unit_4) var(--unit_4);
}

@media screen and (min-width: 641px) {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .content-wrapper {
    padding: var(--unit_6) 0 var(--unit_6) var(--unit_10);
  }

  .content-wrapper > *:nth-child(1) {
    margin-bottom: var(--unit_2);
  }

  .button-wrapper {
    margin-left: var(--unit_4);
    padding: 0 var(--unit_6) 0 0;
  }
}

@media screen and (min-width: 1025px) {
  .wrapper {
    align-items: stretch;
    overflow: hidden;
  }

  .wrapper > svg {
    margin-top: auto;
    min-width: 377px;
    min-height: 162px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .content-wrapper > *:nth-child(2) {
    margin-bottom: var(--unit_4);
  }

  .content-wrapper > *:nth-child(3) {
    margin-top: auto;
    width: auto;
    align-self: flex-start;
  }
}
