.wrapper {
  display: flex;
  align-items: center;
}

.image {
  margin-right: var(--unit_2);
}

.image img {
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important;
}

.content {
  flex-grow: 1;
  width: 0;
}

.name-wrapper {
  display: flex;
  align-items: center;
}

.name,
.description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name {
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  text-decoration: none;
  color: var(--black_100);
}

.name._strong {
  font-weight: bold;
}

.icon-trusted {
  margin-left: var(--unit_1);
}

.description {
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_18px);
  color: var(--gray60_100);
}
