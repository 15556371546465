.wrapper {
  position: relative;
  padding-top: 50%;
  display: flex;
  width: 100%;
}

.wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* stylelint-disable-next-line declaration-no-important */
  border-radius: 4px !important;
}

@media screen and (min-width: 1025px) {
  .wrapper > * {
    /* stylelint-disable-next-line declaration-no-important */
    border-radius: 8px !important;
  }
}
