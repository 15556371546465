.wrapper {
  padding: var(--unit_6) 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray10_100);
}

.wrapper > * + * {
  margin-left: var(--unit_4);
}

.wrapper > *:first-child {
  flex-grow: 1;
  width: 0;
}

.wrapper > *:last-child {
  white-space: nowrap;
}
