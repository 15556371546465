.wrapper {
  margin-bottom: var(--unit_4);
}

.block-reason-wrapper,
.title-wrapper,
.author-wrapper,
.details-wrapper,
.moderation-wrapper {
  margin-bottom: var(--unit_2);
}

.main-image-wrapper {
  margin-bottom: var(--unit_5);
}

.description-wrapper {
  margin-bottom: var(--unit_6);
}

.content-wrapper img {
  max-width: 100%;
}

.content-wrapper > *:first-child {
  margin-top: 0;
}

.content-wrapper a {
  text-decoration: none;
  color: var(--text-main-default);
}

.content-wrapper a:hover {
  color: var(--text-main-hovered);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.content-wrapper ol > li + li,
.content-wrapper ul > li + li {
  margin-top: var(--unit_3);
}

.content-wrapper > p > img {
  border-radius: var(--unit_2);
}

@media screen and (min-width: 1025px) {
  .block-reason-wrapper,
  .title-wrapper,
  .author-wrapper,
  .details-wrapper,
  .moderation-wrapper {
    margin-bottom: var(--unit_3);
  }

  .main-image-wrapper {
    margin-bottom: var(--unit_6);
  }
}
