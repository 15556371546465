.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--unit_2);
  height: 100%;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.delimiter {
  padding-top: var(--unit_4);
}
