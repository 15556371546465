.wrapper {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  cursor: pointer;
}

.wrapper > label {
  flex-direction: row-reverse;
  flex: 1 1 auto;
  gap: var(--unit_3);
  cursor: pointer;
}

.wrapper > label > span {
  min-width: 44px;
  background-color: var(--light-control-state-disabled);
  border-color: var(--light-control-state-disabled);
}

.wrapper > label *:nth-child(3) {
  margin-left: 0;
  flex: 1 1 auto;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_16px);
  color: var(--light-text-seconary);
  background-color: transparent;
  border-color: transparent;
}

@media screen and (min-width: 641px) {
  .wrapper {
    flex: 0 1 auto;
  }

  .wrapper > label {
    flex: 0 1 auto;
  }

  .wrapper > label *:nth-child(3) {
    flex: 0 1 auto;
    text-align: right;
  }
}
