.card-wrapper {
  height: 100%;
}

.headnote {
  margin-bottom: var(--unit_4);
  display: flex;
  justify-content: space-between;
}

.content-wrapper {
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-wrapper._has-image {
  height: calc(100% - 145px);
}

.image-wrapper {
  position: relative;
  display: flex;
}

.image-wrapper > div {
  /* stylelint-disable-next-line declaration-no-important */
  border-top-left-radius: 3px !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-top-right-radius: 3px !important;
}

.title {
  margin-bottom: var(--unit_4);
  display: block;
  font-size: var(--fontSize_18px);
  font-weight: bold;
  line-height: var(--lineHeight_24px);
  text-decoration: none;
  letter-spacing: -0.5px;
  color: var(--black_100);
}

.subtitle {
  margin-bottom: var(--unit_4);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  color: var(--gray60_100);
}

.footnote-wrapper {
  margin-top: auto;
}

.footnote-wrapper > * + * {
  margin-top: var(--unit_4);
}

.footnote {
  display: flex;
  justify-content: space-between;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--gray60_100);
}

.footnote > *:first-child {
  margin-right: auto;
  white-space: nowrap;
}

.footnote > * + * {
  margin-left: var(--unit_3);
  white-space: nowrap;
}

.status-wrapper {
  margin-left: auto;
}

@media screen and (min-width: 641px) {
  .content-wrapper {
    padding: var(--unit_6);
  }

  .content-wrapper._has-image {
    height: calc(100% - 180px);
  }

  .title {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    overflow: hidden;
    max-height: calc(var(--lineHeight_24px) * 4);
    line-height: var(--lineHeight_24px);
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .subtitle {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    overflow: hidden;
    max-height: calc(var(--lineHeight_22px) * 4);
    line-height: var(--lineHeight_22px);
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
