.thumbs-gallery .slide {
  position: relative;
  margin-top: 0;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
}

.thumbs-gallery .slide::before {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: '';
  background: var(--black_100);
}

.thumbs-gallery .slide::after {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: '';
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  transition: border-color 0.2s ease-in-out;
}

.thumbs-gallery .slide.is-active::after {
  border-color: var(--primary_100);
}

.thumbs-gallery .slide > * {
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
}

.thumbs-gallery .slide.is-active > * {
  opacity: 1;
}

.thumbs-gallery .slide img {
  width: 100%;
  /* stylelint-disable-next-line declaration-no-important */
  height: 100% !important;
  object-fit: cover;
}
