.wrapper {
  background: var(--gray6_100);
  border-radius: var(--unit_2);
}

.header {
  padding: var(--unit_4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.header > * + * {
  margin-left: var(--unit_4);
}

.header > .icon {
  display: flex;
  transition-timing-function: ease-in-out;
  transition-property: transform;
}

.wrapper._shown > .header > .icon {
  transform: scaleY(-1);
}

.title {
  font-size: var(--fontSize_18px);
  font-weight: bold;
  line-height: var(--lineHeight_24px);
  letter-spacing: -0.5px;
  color: var(--black_100);
}

.content-wrapper {
  overflow: hidden;
  max-height: 0;
  transition-timing-function: ease-in-out;
  transition-property: max-height;
}

.wrapper._shown > .content-wrapper {
  max-height: 10000px;
}

.content {
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 641px) {
  .header {
    padding: var(--unit_6);
  }

  .content {
    padding: 0 var(--unit_6) var(--unit_6) var(--unit_6);
  }
}
