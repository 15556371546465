.social-networks {
    margin-top: 20px;
}

.social-networks__facebook {
    height: 500px;
}

.social-networks__vk {
    margin-bottom: 20px;
}
