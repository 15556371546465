.wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper::-webkit-scrollbar {
  display: none;
}

.inner-wrapper {
  width: 0;
  max-width: 1000px;
  white-space: nowrap;
}

.inner-wrapper > * {
  display: inline-block;
}

.inner-wrapper > * + * {
  margin-left: var(--unit_2);
}
