.wrapper {
  display: block;
}

.tabs {
  display: flex;
}

.title {
  margin-bottom: var(--unit_4);
  font-size: var(--fontSize_14px);
  font-weight: bold;
  line-height: var(--lineHeight_20px);
  cursor: pointer;
  color: var(--black_100);
}

.title + .title {
  margin-left: var(--unit_4);
}

.title._active {
  color: var(--primary_100);
}

.tabs-content {
  position: relative;
}

.tab-content {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  pointer-events: none;
  opacity: 0;
}

.tab-content._active {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}
