.wrapper {
  display: block;
}

.title {
  margin: 0 0 var(--unit_4);
}

.list-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item-wrapper + .item-wrapper .link {
  border-top: 1px solid var(--gray20_100);
}

.link {
  padding: var(--unit_4) 0;
  display: block;
  font-size: var(--fontSize_18px);
  font-weight: bold;
  line-height: var(--lineHeight_24px);
  text-decoration: none;
  color: var(--black_100);
}

.link:hover {
  color: var(--primary_100);
}
