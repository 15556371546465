.wrapper {
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--unit_5);
  color: var(--black_100);
  background: linear-gradient(
    64.4deg,
    var(--light-controls-primary-basic) -35.98%,
    var(--control-negative-secondary-default) 98.23%
  );
  border-radius: var(--unit_2);
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.wrapper._loading {
  opacity: 0.7;
}

.results {
  color: var(--white_100);
  opacity: 0.8;
}

.list-wrapper > * + * {
  margin-top: var(--unit_4);
}

@media screen and (min-width: 641px) {
  .wrapper {
    padding: 60px 88px;
    gap: var(--unit_7);
  }
}
