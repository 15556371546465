.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-bottom: 16px;
}

.search {
  display: flex;
}

.input {
  position: relative;
  margin-right: 20px;
  width: 320px;
}
