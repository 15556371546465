.wrapper {
  display: flex;
}

.wrapper._hover .inner-wrapper,
.wrapper._hover .image-wrapper {
  cursor: pointer;
}

.wrapper._hover .inner-wrapper .title {
  color: #e84260;
}

.image-wrapper {
  width: 186px;
  min-width: 186px;
}

.image-wrapper > * {
  height: 139px;
}

.info-wrapper {
  width: 100%;
}

.inner-wrapper {
  padding-right: var(--unit_4);
}

.category {
  margin-bottom: var(--unit_2);
  padding-right: var(--unit_2);
  display: inline-block;
  font-size: var(--fontSize_10px);
  font-weight: 900;
  line-height: var(--lineHeight_16px);
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e84260;
}

.category:hover {
  color: var(--black_100);
}

.title {
  margin-bottom: var(--unit_4);
  display: inline-block;
  font-size: var(--fontSize_22px);
  font-weight: 600;
  line-height: var(--lineHeight_28px);
  text-decoration: none;
  color: var(--black_100);
}

.description {
  margin: 0 0 var(--unit_4) 0;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_22px);
  color: var(--black_100);
}

.footer-wrapper {
  display: flex;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--gray60_100);
}

.footer-wrapper > * + * {
  margin-left: var(--unit_4);
}

.views {
  display: flex;
  align-items: center;
}

.views > svg {
  margin-right: 6px;
}
