.wrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.wrapper:hover > *,
.wrapper:hover svg {
  color: var(--primary_100);
  transition: color 200ms ease-in-out;
}
