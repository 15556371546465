.wrapper {
  padding: var(--unit_4) var(--unit_4) var(--unit_5) var(--unit_4);
  display: flex;
  flex-direction: column;
  background: var(--gray6_100);
  border-radius: var(--unit_2);
}

.controls {
  margin-top: var(--unit_4);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--unit_3) var(--unit_3);
}
