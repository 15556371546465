.wrapper {
  display: flex;
  align-items: center;
}

.wrapper > .tab + .tab {
  margin-left: var(--unit_6);
}

.tab {
  padding: var(--unit_0) 0;
  font-size: var(--fontSize_14px);
  font-weight: bold;
  line-height: var(--lineHeight_20px);
  cursor: pointer;
  color: var(--black_100);
  transition: box-shadow 200ms ease-in-out;
}

.tab._active:not(._disabled) {
  cursor: auto;
  color: var(--primary_100);
  box-shadow: 0 3px 0 0 var(--primary_100);
}

.tab._disabled {
  cursor: auto;
  color: var(--gray20_100);
}
.tab:hover:not(._disabled):not(._active),
.tab._active._disabled {
  box-shadow: 0 3px 0 0 var(--gray10_100);
}
