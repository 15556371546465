.discussed {
  border-radius: 1px;
}

.discussed-header {
  padding: 21px 20px 17px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #e4e4e4;
  border-bottom: 2px solid #182930;
}

.discussed-header__text {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #121212;
}

.discussed-comments {
  display: flex;
  flex-direction: column;
}

.discussed-comments-comment {
  padding: 14px 20px 20px;
  word-wrap: break-word;
  border: solid 1px #e4e4e4;
  border-radius: 1px;
}

.discussed-comments-comment {
  border-bottom: 0;
}

.discussed-comments-comment:first-child {
  border-top: 0;
}

.discussed-comments-comment:last-child {
  border-bottom: solid 1px #e4e4e4;
}

.discussed-comments-comment__title {
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  text-decoration: none;
  color: #121212;
}

.discussed-comments-comment__title:hover,
.discussed-comments-comment__title-hover {
  color: #e84260;
}

.discussed-comments-comment__content {
  position: relative;
  margin-top: 6px;
  display: block;
  font-size: 14px;
  line-height: 1.29;
  text-align: left;
  text-decoration: none;
  color: #121212;
}

.discussed-comments-comment__content::before {
  position: absolute;
  left: -6px;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.29;
  content: '«';
  color: #e84260;
}

.discussed-comments-comment__content::after {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.29;
  content: '»';
  color: #e84260;
}

.discussed-comments-comment-author {
  margin-top: 7px;
  display: flex;
}

.discussed-comments-comment-author__photo {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.discussed-comments-comment-author-info__name {
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  text-decoration: none;
  color: #121212;
}

.discussed-comments-comment-author-info__date {
  position: relative;
  top: -2px;
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: #7a7a7a;
}

@media screen and (max-width: 768px) {
  .discussed {
    background: white;
    justify-content: space-between;
  }
}
