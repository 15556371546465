.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--unit_2);
}

@media screen and (min-width: 641px) {
  .wrapper {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}
