.container {
  break-inside: avoid;
  padding-top: 22px;
  padding-bottom: 13px;
}

.title {
  margin: 0;
  padding: 3px 6px;
  font-size: 18px;
  font-weight: normal;
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button {
  line-height: 23px;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
}

.button:hover,
.button._active {
  color: var(--fill_primary_active_100);
}

@media (max-width: 640px) {
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .title {
    position: sticky;
    top: 0;
    margin: 0 -20px;
    padding: 10px 25px;
    font-size: 20px;
    background: var(--gray20_100);
  }

  .buttons-wrapper {
    align-items: normal;
  }

  .button {
    margin: 0 -20px;
    padding: 10px 25px;
    font-size: 18px;
    border-bottom: 1px solid var(--gray20_100);
  }
}
