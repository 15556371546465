.wrapper {
  display: flex;
  flex-direction: column;
}

.search-and-regions-wrapper {
  display: none;
}

.search-and-regions-wrapper._active,
.top-margin {
  margin-bottom: var(--unit_4);
}

.search-and-regions-wrapper._active {
  padding: var(--unit_6) 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray10_100);
}

.search-and-regions-wrapper._active > * + * {
  margin-left: var(--unit_4);
}

.search-and-regions-wrapper._active > *:first-child {
  flex-grow: 1;
  width: 0;
}

.search-and-regions-wrapper._active > *:last-child {
  white-space: nowrap;
}

.group-wrapper {
  margin-bottom: var(--unit_4);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-wrapper > *:first-child {
  margin-right: auto;
}

.group-wrapper > * + * {
  margin-left: var(--unit_4);
}

.group-wrapper > .title {
  margin-bottom: 0;
}

.title {
  margin-top: 0;
  margin-bottom: var(--unit_4);
  display: flex;
  font-size: var(--fontSize_22px);
  line-height: var(--lineHeight_28px);
}

.title > svg {
  margin-left: var(--unit_1);
}

.content-type-wrapper {
  width: 100%;
}

.tag-scrolling-wrapper {
  margin-bottom: var(--unit_4);
}

@media screen and (min-width: 641px) {
  .search-and-regions-wrapper._active,
  .top-margin {
    margin-bottom: var(--unit_6);
  }

  .group-wrapper {
    margin-bottom: var(--unit_6);
  }

  .group-wrapper > *:last-child {
    margin-left: var(--unit_4);
  }

  .title {
    margin-bottom: var(--unit_2);
    font-size: var(--fontSize_28px);
    line-height: var(--lineHeight_36px);
  }

  .title > svg {
    margin-left: var(--unit_2);
  }

  .tag-scrolling-wrapper {
    margin-bottom: var(--unit_6);
  }

  .bread-crumbs-wrapper {
    margin-bottom: var(--unit_6);
  }

  .ordering-buttons-wrapper + .listing-type-wrapper {
    /* stylelint-disable-next-line declaration-no-important */
    margin-left: 0;
  }
}

@media screen and (min-width: 1025px) {
  .title {
    font-size: 36px;
    line-height: 40px;
  }

  .ordering-buttons-wrapper + .listing-type-wrapper {
    /* stylelint-disable-next-line declaration-no-important */
    margin-left: var(--unit_4);
  }
}
