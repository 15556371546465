.wrapper {
  padding: var(--unit_2);
  display: flex;
  align-items: flex-start;
  height: 52px;
  background: linear-gradient(273.91deg, #bec3fe 0.49%, #ffe1ff 59.3%, #ffdcc8 73.73%, #ffebaf 99.03%);
  border-radius: var(--unit_2);
}

.title {
  font-size: var(--fontSize_14px);
  font-weight: bold;
  line-height: var(--lineHeight_20px);
  color: var(--black_100);
}

.description {
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--gray60_100);
}

.switch-wrapper {
  margin-left: auto;
}

.switch-wrapper * {
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
}
