.like {
  padding: 24px 0 0;
  width: 100%;
  max-width: 736px;
  font-family: Lato, Verdana, sans-serif;
}

.like__title {
  margin: 0 0 16px;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.32px;
  color: #121212;
}

.like__button-wrapper {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.like__button {
  padding: 4px 12px;
  display: flex;
  box-sizing: border-box;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  line-height: 20px;
  cursor: pointer;
  color: #0468ff;
  background-color: transparent;
  border: 1px solid #0468ff;
  border-radius: 4px;
}

.like__button__check {
  background-color: rgba(4, 104, 255, 0.1);
}

.like__button:first-of-type {
  margin-right: 8px;
}

.like__button:focus {
  box-shadow: inset 0 0 1px 0 rgb(131 180 255);
}

.like__button:hover {
  background-color: rgba(4, 104, 255, 0.1);
}

.like__button:active {
  background-color: rgba(15, 72, 157, 0.2);
}

.like__button-text {
  margin: 0 6px;
}

.like__views-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  color: #737a8e;
}

.like__views {
  margin-left: 6px;
}

.like__hashtag-wrapper {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #0468ff;
}

.like__hashtag {
  margin-right: 6px;
}

.like__hashtag:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 1024px) {
  .like {
    max-width: 656px;
  }
}

@media screen and (max-width: 540px) {
  .like__button-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .like__button {
    padding: 9px 16px;
    font-size: 16px;
    line-height: 22px;
  }

  .like__button:first-of-type {
    margin: 0 0 8px;
  }

  .like__views-wrapper {
    margin: 16px 0 0;
  }

  .like__hashtag-wrapper {
    text-align: justify;
  }
}
