.wrapper {
  margin: var(--unit_10) 0 0;
  width: calc(66.66% - 0.5rem);
}

.title-wrapper {
  margin-bottom: var(--unit_5);
  padding-bottom: var(--unit_5);
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 2px solid var(--black_100);
}

.similar-article-wrapper + .similar-article-wrapper {
  margin-top: var(--unit_6);
  padding-top: var(--unit_5);
  border-top: 1px solid var(--gray20_100);
}
