.wrapper {
  position: relative;
  display: flex;
}

.wrapper > textarea:first-child {
  transition: height 200ms ease-in-out;
}

.wrapper.has_counter > textarea:first-child {
  padding-bottom: var(--unit_4);
}

.counter {
  position: absolute;
  bottom: var(--unit_1);
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--gray40_100);
}

.counter._max {
  color: var(--error_100);
}

.wrapper._size_xs > .counter {
  right: var(--unit_2);
}

.wrapper._size_m > .counter {
  right: var(--unit_3);
}
