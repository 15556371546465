.breadcrumbs {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
}

.breadcrumbs__item {
  position: relative;
  line-height: 1.33;
  color: #7a7a7a;
}

.breadcrumbs__item::after {
  margin: 0 8px;
  display: inline-block;
  width: 6px;
  height: 8px;
  content: '';
  background: url('img/breadcrumb-arrow.svg') no-repeat center;
}

.breadcrumbs__item:last-child::after {
  margin: 0;
  content: '';
  background: none;
}

.breadcrumbs__link {
  font-size: 12px;
  text-decoration: none;
  color: inherit;
}

.breadcrumbs__link:hover {
  color: #121212;
}

.breadcrumbs__item-empty {
  font-size: 12px;
  color: inherit;
}

.breadcrumbs-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .breadcrumbs-dekstop {
    display: none;
  }

  .breadcrumbs-mobile {
    display: flex;
  }

  .breadcrumbs {
    margin-top: 40px;
    line-height: 1.33;
  }

  .breadcrumbs-hidden {
    display: none;
  }
}
