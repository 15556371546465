.wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: var(--unit_2);
}

.link {
  text-decoration: none;
}

@media screen and (min-width: 641px) {
  .wrapper {
    align-items: center;
  }
}
